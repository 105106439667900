import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const BASE_URL = process.env.VUE_APP_BASE_URL;

export default {
    async login(user) {
        var response = await axios.post(
            API_URL + "/login",
            {
                email: user.email,
                password: user.password,
            },
            {
                headers: {
                    Accept: "application/vnd.api+json",
                    "Content-Type": "application/vnd.api+json",
                },
            }
        );
        if (response.data.access_token) {
            localStorage.setItem(
                "user",
                JSON.stringify(response.data.access_token)
            );
        }
        return response.data;
    },

    async logout() {
        try {
            await axios.post(
                API_URL + "/logout",
                {},
                { headers: authHeader() }
            );
        } catch (error) {
            console.error("Logout error:", error);
        }
        localStorage.removeItem("user");
    },
    async passwordForgot(userEmail) {
        var response = await axios.post(API_URL + "/password-forgot", {
            redirect_url: BASE_URL + "/password-reset",
            email: userEmail,
        });
        return response.status;
    },

    async passwordReset(passwordDTO) {
        var response = await axios.post(API_URL + "/password-reset", {
            password: passwordDTO.newPassword,
            password_confirmation: passwordDTO.confirmPassword,
            email: passwordDTO.email,
            token: passwordDTO.token,
        });
        return response.status;
    },
};
