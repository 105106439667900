<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 role">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">
                                    Meadow Heights Students List
                                </h5>
                            </div>
                        </div>
                    </div>

                    <!-- Card body -->
                    <div class="px-0 pb-0 card-body">
                        <div
                            class="px-3 d-flex justify-content-between align-items-center"
                        >
                            <!-- Search -->
                            <div
                                class="dataTable-search search-block col-2 ms-3"
                            >
                                <material-input
                                    v-model:value="search"
                                    placeholder="Search..."
                                    class="mb-1 dataTable-input search-input-table"
                                    variant="static"
                                />
                            </div>
                            <button
                                class="btn btn-primary"
                                @click="downloadExcel"
                            >
                                Download Excel
                            </button>
                        </div>

                        <!-- Table -->
                        <div
                            class="table-responsive"
                            ref="scrollableTable"
                            @mousedown="startDrag"
                            @mouseup="stopDrag"
                            @mouseleave="stopDrag"
                            @mousemove="onDrag"
                        >
                            <!-- Student Table -->
                            <table
                                id="student-list"
                                ref="table"
                                class="table table-flush"
                            >
                                <thead class="thead-light">
                                    <tr>
                                        <th title="FirstName">First Name</th>
                                        <th title="MiddleName">Middle Name</th>
                                        <th title="LastName">Last Name</th>
                                        <th title="DateOfBirth">
                                            Date of Birth
                                        </th>
                                        <th title="Gender">Gender</th>
                                        <th title="Campus">Campus</th>
                                        <th title="School Attended">
                                            School Attended
                                        </th>
                                        <th title="YearLevel">Year Level</th>
                                        <th title="PrimaryParentName">
                                            Primary Parent Name
                                        </th>
                                        <th title="PrimaryParentNumber">
                                            Primary Parent Number
                                        </th>
                                        <th title="PrimaryParentEmail">
                                            Primary Parent Email
                                        </th>
                                        <th title="SecondaryParentName">
                                            Secondary Parent Name
                                        </th>
                                        <th title="SecondaryParentNumber">
                                            Secondary Parent Number
                                        </th>
                                        <th title="SecondaryParentEmail">
                                            Secondary Parent Email
                                        </th>
                                        <th title="Disabilities">
                                            Disabilities
                                        </th>
                                        <th title="ImpairmentDetails">
                                            Impairment Details
                                        </th>
                                        <th title="MedicalConditions">
                                            Medical Conditions
                                        </th>
                                        <th title="MedicalConditionDetails">
                                            Medical Condition Details
                                        </th>
                                        <th title="ExtraCurricularActivities">
                                            Quran Memorization
                                        </th>
                                        <th title="ImageConsent">
                                            Image Consent
                                        </th>
                                        <th title="AmountOwed">Amount Owed</th>
                                        <th title="Status">Status</th>
                                        <th
                                            data-sortable="false"
                                            class="text-end"
                                        >
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="text-sm"></tbody>
                            </table>
                        </div>
                    </div>

                    <!-- Pagination -->
                    <div
                        class="d-flex justify-content-between flex-wrap align-items-center"
                    >
                        <!-- Pagination Info -->
                        <div class="ms-3">
                            <p class="text-sm">
                                Showing
                                {{
                                    (metaPage?.CurrentPage - 1) *
                                        metaPage?.PageSize +
                                    1
                                }}
                                to
                                {{
                                    Math.min(
                                        metaPage?.CurrentPage *
                                            metaPage?.PageSize,
                                        metaPage?.TotalCount
                                    )
                                }}
                                of {{ metaPage?.TotalCount }} entries
                            </p>
                        </div>
                        <!-- Pagination Component -->
                        <BasePagination
                            v-model="pagination.currentPage"
                            class="pagination-success pagination-md me-3"
                            :per-page="pagination.perPage"
                            :total="pagination.total"
                            :value="pagination.currentPage"
                            @click="handlePageChange"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import eventTable from "@/mixins/eventTable.js";
import store from "@/store";
import _ from "lodash";
import * as XLSX from "xlsx";

import MaterialInput from "@/components/MaterialInput.vue";
import BasePagination from "@/components/BasePagination.vue";

export default {
    name: "Meadow Heights Student List",
    components: {
        MaterialInput,
        BasePagination,
    },
    data() {
        return {
            search: "",
            dataTable: null,
            pagination: {
                currentPage: 1,
                perPage: 25,
                total: 0,
            },
            pendingFilter: false,
            expandedRows: {},
            isDragging: false,
            startX: 0,
            scrollLeft: 0,
        };
    },
    mixins: [eventTable],
    computed: {
        studentsList() {
            return this.$store.getters["students/getStudentsData"];
        },
        metaPage() {
            return this.$store.getters["students/getStudentsMeta"];
        },
    },
    watch: {
        metaPage: {
            deep: true,
            handler(newVal) {
                this.pagination.total = newVal.TotalCount;
                this.pagination.currentPage = newVal.CurrentPage;
                this.pagination.perPage = newVal.PageSize;
                this.updateDataTable();
            },
        },
        studentsList() {
            this.populateDataTable();
        },
        search: _.debounce(function () {
            this.pagination.currentPage = 1;
            this.fetchStudentsData();
        }, 500),
    },
    async mounted() {
        await this.initializeDataTable();
        await this.fetchStudentsData();

        this.initializeDragScroll();
    },
    methods: {
        startDrag(e) {
            this.isDragging = true;
            this.startX = e.pageX - this.$refs.scrollableTable.offsetLeft;
            this.scrollLeft = this.$refs.scrollableTable.scrollLeft;
        },

        stopDrag() {
            this.isDragging = false;
        },

        onDrag(e) {
            if (!this.isDragging) return;
            e.preventDefault();
            const x = e.pageX - this.$refs.scrollableTable.offsetLeft;
            const walk = x - this.startX;
            this.$refs.scrollableTable.scrollLeft = this.scrollLeft - walk;
        },
        initializeDragScroll() {
            const slider = this.$refs.table; // Adjust this reference to your table container
            let isDown = false;
            let startX;
            let scrollLeft;

            slider.addEventListener("mousedown", (e) => {
                isDown = true;
                slider.classList.add("active");
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });

            slider.addEventListener("mouseleave", () => {
                isDown = false;
                slider.classList.remove("active");
            });

            slider.addEventListener("mouseup", () => {
                isDown = false;
                slider.classList.remove("active");
            });

            slider.addEventListener("mousemove", (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; // The number 3 determines the scroll speed
                slider.scrollLeft = scrollLeft - walk;
            });
        },
        async initializeDataTable() {
            this.dataTable = new DataTable(this.$refs.table, {
                searchable: false,
                fixedHeight: false,
                perPage: this.pagination.perPage,
                perPageSelect: [10, 25, 50, 100, 1000], // Allows changing rows per page
                columns: [
                    // Specify which columns are sortable/searchable
                    // ... Define the properties for each column
                    { select: [18], sortable: false }, // Assuming 18th column is 'Actions'
                ],
            });
            this.dataTable.on("datatable.perpage", (perPage) => {
                this.pagination.perPage = perPage;
                this.fetchStudentsData();
            });
            document.querySelector(".dataTable-bottom").remove();
        },

        async fetchStudentsData() {
            const params = {
                page: {
                    number: this.pagination.currentPage,
                    size: this.pagination.perPage,
                },
                filter: {
                    ...(this.search.trim() !== ""
                        ? { search: this.search }
                        : {}),
                    campus: "Meadow Heights",
                },
            };
            await store.dispatch("students/getStudents", params);
            this.updateDataTable();
        },

        async fetchFilteredStudents() {
            const params = {
                page: {
                    number: this.pagination.currentPage,
                    size: this.pagination.perPage,
                },
                filter: this.pendingFilter ? { status: "Approved" } : {},
            };

            if (this.search.trim() !== "") {
                params.filter.name = this.search;
            }

            await this.$store.dispatch("students/getStudents", params);
            this.updateDataTable();
        },

        toggleDetails(index) {
            this.$set(this.expandedRows, index, !this.expandedRows[index]);
            this.populateDataTable(); // Refresh the table to show/hide detail rows
        },

        populateDataTable() {
            if (!this.studentsList || this.studentsList.length === 0) {
                this.dataTable.setMessage("No results match your search query");
                return;
            }
            // Filter out students with a status of "Pending"
            const filteredStudents = this.studentsList.filter(
                (student) => student.Status !== "Pending"
            );

            const dataStudents = filteredStudents.map((student) => {
                // Primary parent/guardian details
                const primaryParentName = `${student.ParentGuardian.PrimaryFirstName} ${student.ParentGuardian.PrimaryLastName}`;
                const primaryParentNumber =
                    student.ParentGuardian.PrimaryContactNumber;
                const primaryParentEmail = student.ParentGuardian.PrimaryEmail;

                // Secondary parent/guardian details
                const secondaryParentName = `${student.ParentGuardian.SecondaryFirstName} ${student.ParentGuardian.SecondaryLastName}`;
                const secondaryParentNumber =
                    student.ParentGuardian.SecondaryContactNumber;
                const secondaryParentEmail =
                    student.ParentGuardian.SecondaryEmail;
                return [
                    student.FirstName,
                    student.MiddleName,
                    student.LastName,
                    student.DateOfBirth
                        ? new Date(student.DateOfBirth).toLocaleDateString(
                              "en-GB",
                              {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                              }
                          )
                        : "",
                    student.Gender,
                    student.Campus,
                    student.SchoolName,
                    student.YearLevel,
                    primaryParentName, // Primary parent name
                    primaryParentNumber, // Primary parent number
                    primaryParentEmail, // Primary parent email
                    secondaryParentName, // Secondary parent name
                    secondaryParentNumber, // Secondary parent number
                    secondaryParentEmail, // Secondary parent email
                    student.Disabilities ? "Yes" : "No",
                    student.ImpairmentDetails,
                    student.MedicalConditions ? "Yes" : "No",
                    student.MedicalConditionDetails,
                    student.ExtraCurricularActivities ? "Yes" : "No",
                    student.ImageConsent ? "Yes" : "No",
                    student.AmountOwed //Convert to currency
                        ? new Intl.NumberFormat("en-GB", {
                              style: "currency",
                              currency: "AUD",
                          }).format(student.AmountOwed)
                        : "Paid",
                    student.Status,
                    // Actions column
                    `<div class="text-end">
                    ${this.actionEditButton(student.Id, "Edit Student")}
                   </div>`,
                ];
            });

            this.dataTable.data = [];
            this.dataTable.refresh();
            this.dataTable.insert({ data: dataStudents });
            this.eventToCall({
                table: this.dataTable,
                myUserId: null,
                redirectPath: "Edit Student",
                getPath: "students/getStudents",
                textDefaultData: "students",
            });
        },

        updateDataTable() {
            if (this.dataTable) {
                this.dataTable.options.perPage = this.pagination.perPage;
                this.dataTable.refresh();
            }
        },

        handlePageChange(page) {
            this.pagination.currentPage = page;
            this.fetchStudentsData();
        },

        downloadExcel() {
            const columnNames = [
                "First Name",
                "Middle Name",
                "Last Name",
                "Date of Birth",
                "Gender",
                "Campus",
                "School Attended",
                "Year Level",
                "Primary Parent Name",
                "Primary Parent Number",
                "Primary Parent Email",
                "Secondary Parent Name",
                "Secondary Parent Number",
                "Secondary Parent Email",
                "Disabilities",
                "Impairment Details",
                "Medical Conditions",
                "Medical Condition Details",
                "Quran Memorization",
                "Image Consent",
                "Amount Owed",
                "Status",
            ];

            const data = [
                columnNames,
                ...this.studentsList.map((student) => [
                    student.FirstName,
                    student.MiddleName,
                    student.LastName,
                    student.DateOfBirth
                        ? new Date(student.DateOfBirth).toLocaleDateString(
                              "en-GB",
                              {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                              }
                          )
                        : "",
                    student.Gender,
                    student.Campus,
                    student.SchoolAttended,
                    student.YearLevel,
                    `${student.ParentGuardian.PrimaryFirstName} ${student.ParentGuardian.PrimaryLastName}`,
                    student.ParentGuardian.PrimaryContactNumber,
                    student.ParentGuardian.PrimaryEmail,
                    `${student.ParentGuardian.SecondaryFirstName} ${student.ParentGuardian.SecondaryLastName}`,
                    student.ParentGuardian.SecondaryContactNumber,
                    student.ParentGuardian.SecondaryEmail,
                    student.Disabilities ? "Yes" : "No",
                    student.ImpairmentDetails,
                    student.MedicalConditions ? "Yes" : "No",
                    student.MedicalConditionDetails,
                    student.ExtraCurricularActivities ? "Yes" : "No",
                    student.ImageConsent ? "Yes" : "No",
                    student.AmountOwed
                        ? new Intl.NumberFormat("en-GB", {
                              style: "currency",
                              currency: "AUD",
                          }).format(student.AmountOwed)
                        : "Paid",
                    student.Status,
                ]),
            ];

            //Get today's date and time
            const today = new Date();
            //Get the current date
            const date =
                today.getFullYear() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getDate();
            //Get the current time
            const time =
                today.getHours() +
                ":" +
                today.getMinutes() +
                ":" +
                today.getSeconds();
            //Display date and time
            const dateTime = date + " " + time;

            // Create a workbook and add a worksheet
            const ws = XLSX.utils.aoa_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "MeadowHeightsStudents");

            // Define filename
            const fileName = "MeadowHeightsStudents_" + dateTime + ".xlsx";

            // Write workbook and trigger download
            XLSX.writeFile(wb, fileName);
        },
    },
};
</script>

<style>
/* Add this to your CSS file */
.dataTable-table {
    overflow-x: auto;
}

.dataTable-head {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
}

.table-responsive {
    overflow-x: auto;
    max-width: 100%;
    cursor: grab; /* Change cursor to indicate draggability */
}

.table-responsive::-webkit-scrollbar {
    display: none; /* Optional: Hide the scrollbar */
}

.table-responsive:active {
    cursor: grabbing;
}
</style>
