<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-lg-12 position-relative z-index-2">
                <div class="row mb-4">
                    <div class="col-lg-4 col-md-6 mt-4 mb-4">
                        <chart-holder-card
                            title="Website Views"
                            subtitle="Last Campaign Performance"
                            update="campaign sent 2 days ago"
                        >
                            <reports-bar-chart
                                :chart="{
                                    labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
                                    datasets: {
                                        label: 'Sales',
                                        data: [50, 20, 10, 22, 50, 10, 40],
                                    },
                                }"
                            />
                        </chart-holder-card>
                    </div>
                    <div class="col-lg-4 col-md-6 mt-4 mb-4">
                        <chart-holder-card
                            title="Payments"
                            subtitle="(<span class='font-weight-bolder'>+15%</span>) increase in payments this month"
                            update="updated 4 min ago"
                            color="success"
                        >
                            <reports-line-chart
                                :chart="{
                                    labels: [
                                        'Apr',
                                        'May',
                                        'Jun',
                                        'Jul',
                                        'Aug',
                                        'Sep',
                                        'Oct',
                                        'Nov',
                                        'Dec',
                                    ],
                                    datasets: {
                                        label: 'Mobile apps',
                                        data: [
                                            50, 40, 300, 320, 500, 350, 200,
                                            230, 500,
                                        ],
                                    },
                                }"
                            />
                        </chart-holder-card>
                    </div>
                    <div class="col-lg-4 mt-4 mb-3">
                        <chart-holder-card
                            title="Completed Tasks"
                            subtitle="Last Campaign Performance"
                            update="just updated"
                            color="dark"
                        >
                            <reports-line-chart
                                id="tasks-chart"
                                :chart="{
                                    labels: [
                                        'Apr',
                                        'May',
                                        'Jun',
                                        'Jul',
                                        'Aug',
                                        'Sep',
                                        'Oct',
                                        'Nov',
                                        'Dec',
                                    ],
                                    datasets: {
                                        label: 'Mobile apps',
                                        data: [
                                            50, 40, 300, 220, 500, 250, 400,
                                            230, 500,
                                        ],
                                    },
                                }"
                            />
                        </chart-holder-card>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-6">
                        <mini-statistics-card
                            :title="{ text: 'Students', value: 281 }"
                            detail="<span class='text-success text-sm font-weight-bolder'>+55%</span> than last week"
                            :icon="{
                                name: 'person',
                                color: 'text-white',
                                background: 'dark',
                            }"
                        />
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
                        <mini-statistics-card
                            :title="{ text: 'Any Statistic', value: '2,300' }"
                            detail="<span class='text-success text-sm font-weight-bolder'>+3%</span> than last month"
                            :icon="{
                                name: 'leaderboard',
                                color: 'text-white',
                                background: 'primary',
                            }"
                        />
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
                        <mini-statistics-card
                            :title="{ text: 'Revenue', value: '69k' }"
                            detail="<span class='text-success text-sm font-weight-bolder'>+420%</span> than yesterday"
                            :icon="{
                                name: 'leaderboard',
                                color: 'text-white',
                                background: 'success',
                            }"
                        />
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
                        <mini-statistics-card
                            :title="{ text: 'Enrolments', value: '+123' }"
                            detail="Just updated"
                            :icon="{
                                name: 'person_add',
                                color: 'text-white',
                                background: 'info',
                            }"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="row"></div>
    </div>
</template>
<script>
import ChartHolderCard from "./components/ChartHolderCard.vue";
import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
import ReportsLineChart from "./components/ReportsLineChart.vue";
import MiniStatisticsCard from "./components/MiniStatisticsCard.vue";

export default {
    name: "Dashboard",
    components: {
        ChartHolderCard,
        ReportsBarChart,
        ReportsLineChart,
        MiniStatisticsCard,
    },
};
</script>
