import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;
export default {
    async getRoles(params) {
        const response = await axios.get(API_URL + "/roles", {
            headers: authHeader(),
            params: params,
        });
        return {
            data: response.data.Roles, // Access the Roles array
            meta: response.data.Metadata, // Access the Metadata object
        };
    },
    async getRole(roleId) {
        const response = await axios.get(API_URL + "/roles/" + roleId, {
            headers: authHeader(),
        });
        return response.data;
    },

    async addRole(role) {
        const requestData = { name: role.Name };

        const response = await axios.post(API_URL + "/roles", requestData, {
            headers: authHeader(),
        });

        return response.data; // Adjust based on how your API responds
    },

    async deleteRole(roleId) {
        const response = await axios.delete(API_URL + "/roles/" + roleId, {
            headers: authHeader(),
        });
        return response.data; // Adjust if needed
    },

    async editRole(role) {
        const updatedRoleData = { name: role.Name };
        const response = await axios.put(
            API_URL + "/roles/" + role.Id,
            updatedRoleData,
            {
                headers: authHeader(),
            }
        );
        return response.data; // Adjust if needed
    },
};
