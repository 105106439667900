<!--
=========================================================
* Vue Material Dashboard 2 Laravel PRO - v3.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-material-dashboard-2-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->
<template>
    <sidenav
        v-if="showSidenav"
        :custom_class="color"
        :class="[isRTL ? 'fixed-end' : 'fixed-start']"
    />
    <main
        class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden"
    >
        <!-- nav -->
        <navbar
            v-if="showNavbar"
            :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
            :color="isAbsolute ? 'text-white opacity-8' : ''"
            :min-nav="navbarMinimize"
        />
        <router-view />
        <app-footer v-show="showFooter" />
    </main>
</template>
<script>
import Sidenav from "@/components/Sidenav";
import Navbar from "@/components/Navbar.vue";
import AppFooter from "@/components/Footer.vue";
import { mapMutations, mapState } from "vuex";

export default {
    name: "App",
    components: {
        Sidenav,
        Navbar,
        AppFooter,
    },
    computed: {
        ...mapState([
            "isRTL",
            "color",
            "isAbsolute",
            "isNavFixed",
            "navbarFixed",
            "absolute",
            "showSidenav",
            "showNavbar",
            "showFooter",
            "showConfig",
            "hideConfigButton",
        ]),
    },
    beforeMount() {
        const sidenav = document.getElementsByClassName("g-sidenav-show")[0];

        if (window.innerWidth > 1200) {
            sidenav.classList.add("g-sidenav-pinned");
        }
    },
    methods: {
        ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
    },
};
</script>
<style>
.dataTable-pagination-list .active a {
    background-image: linear-gradient(
        195deg,
        #66bb6a 0%,
        #43a047 100%
    ) !important;
}
</style>
