import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Calendar from "../views/applications/Calendar.vue";
import Illustration from "../views/auth/signin/Illustration.vue";
import ResetCover from "../views/auth/reset/Cover.vue";
import Login from "../views/Login.vue";
import PasswordForgot from "../views/PasswordForgot.vue";
import PasswordReset from "../views/PasswordReset.vue";
import UserProfile from "../views/profile/UserProfile.vue";
import RolesList from "../views/settingsConfiguration/roles/RolesList.vue";
import EditRole from "../views/settingsConfiguration/roles/EditRole.vue";
import AddRole from "../views/settingsConfiguration/roles/AddRole.vue";
import UsersList from "../views/settingsConfiguration/users/UsersList.vue";
import EditUser from "../views/settingsConfiguration/users/edit-user/EditUser.vue";
import AddUser from "../views/settingsConfiguration/users/add-user/AddUser.vue";
import DallasStudentsList from "../views/students/DallasStudentsList.vue";
import MeadowHeightsStudentsList from "../views/students/MeadowHeightsStudentsList.vue";
import EditStudent from "../views/students/EditStudent.vue";
import EnrolmentList from "../views/enrolments/EnrolmentList.vue";
import EditEnrolment from "../views/enrolments/EditEnrolment.vue";
import PaymentsList from "../views/payments/PaymentList.vue";
import AddPayment from "../views/payments/AddPayment.vue";
import store from "@/store";

const routes = [
    {
        path: "/",
        name: "/",
        redirect: "/login",
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
            authRequired: true,
            permission: null,
        },
    },
    {
        path: "/applications/calendar",
        name: "Calendar",
        component: Calendar,
        meta: {
            authRequired: true,
            permission: null,
        },
    },
    {
        path: "/profile",
        name: "User Profile",
        component: UserProfile,
        meta: {
            authRequired: true,
            permission: null,
        },
    },
    //#region Enrolments
    {
        path: "/enrolments/",
        name: "Enrolments List",
        component: EnrolmentList,
        meta: {
            authRequired: true,
            permission: null,
        },
    },
    {
        path: "/enrolments/edit/:id",
        name: "Edit Enrolment",
        component: EditEnrolment,
        meta: {
            authRequired: true,
            permission: null,
        },
    },
    //#endregion
    //#region Students
    {
        path: "/students/dallas",
        name: "DallasStudentsList",
        component: DallasStudentsList,
        meta: {
            authRequired: true,
            permission: null,
        },
    },
    {
        path: "/students/meadowheights",
        name: "MeadowHeightsStudentsList",
        component: MeadowHeightsStudentsList,
        meta: {
            authRequired: true,
            permission: null,
        },
    },
    {
        path: "/students/edit/:id",
        name: "Edit Student",
        component: EditStudent,
        meta: {
            authRequired: true,
            permission: null,
        },
    },
    //#endregion
    //#region Payments
    {
        path: "/payments",
        name: "Payments List",
        component: PaymentsList,
        meta: {
            authRequired: true,
            permission: ["admin"],
        },
    },
    {
        path: "/payments/add",
        name: "Add Payment",
        component: AddPayment,
        meta: {
            authRequired: true,
            permission: ["admin"],
        },
    },
    //#endregion
    //#region Authentication
    {
        path: "/authentication/signin/illustration",
        name: "Signin Illustration",
        component: Illustration,
    },
    {
        path: "/authentication/reset/cover",
        name: "Reset Cover",
        component: ResetCover,
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            authRequired: false,
            permission: null,
        },
    },
    {
        path: "/password-forgot",
        name: "PasswordForgot",
        component: PasswordForgot,
        meta: {
            authRequired: false,
            permission: null,
        },
    },
    {
        path: "/password-reset",
        name: "PasswordReset",
        component: PasswordReset,
        meta: {
            authRequired: false,
            permission: null,
        },
    },
    //#endregion
    //#region Settings Configuration
    {
        path: "/roles/",
        name: "Roles List",
        component: RolesList,
        meta: {
            authRequired: true,
            permission: ["admin"],
        },
    },
    {
        path: "/roles/add",
        name: "Add Role",
        component: AddRole,
        meta: {
            authRequired: true,
            permission: ["admin"],
        },
    },
    {
        path: "/roles/edit/:id",
        name: "Edit Role",
        component: EditRole,
        meta: {
            authRequired: true,
            permission: ["admin"],
        },
    },
    {
        path: "/users",
        name: "Users List",
        component: UsersList,
        meta: {
            authRequired: true,
            permission: ["admin"],
        },
    },
    {
        path: "/users/add",
        name: "Add User",
        component: AddUser,
        meta: {
            authRequired: true,
            permission: ["admin"],
        },
    },
    {
        path: "/users/edit/:id",
        name: "Edit User",
        component: EditUser,
        meta: {
            authRequired: true,
            permission: ["admin"],
        },
    },
    //#endregion
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active",
});

//eslint-disable-next-line no-unused-vars
router.beforeEach(async (to, from, next) => {
    const { authRequired, permission } = to.meta;
    const isLoggedIn = store.getters["auth/isLoggedIn"];

    if (authRequired && !isLoggedIn) {
        return next({ name: "Login", query: { returnUrl: to.path } });
    }

    if (isLoggedIn && to.name === "Login") {
        return next({ name: "Dashboard" });
    }

    if (permission) {
        try {
            await store.dispatch("profile/getProfile");
            const userPermission =
                store.getters["profile/getUserProfile"].Role.Name;
            if (!permission.includes(userPermission)) {
                return next({ name: "Dashboard" });
            }
        } catch (error) {
            console.error("Permission check failed:", error);
            await store.dispatch("auth/logout");
            return next({ name: "Login" });
        }
    }

    next();
});

export default router;
