import studentsService from "../services/students.service";

const initialState = {
    students: null,
    student: null,
};

export const students = {
    namespaced: true,
    state: initialState,
    actions: {
        async getStudents({ commit }, options) {
            const response = await studentsService.getStudents(options);
            commit("setStudents", response);
        },
        async getStudent({ commit }, studentId) {
            const student = await studentsService.getStudent(studentId);
            commit("setStudent", student);
        },
        async editStudent({ commit }, student) {
            const updatedStudent = await studentsService.editStudent(student);
            commit("setStudent", updatedStudent);
        },
        async approveEnrolment({ commit }, enrolmentId) {
            await studentsService.approveEnrolment(enrolmentId);
            // Handle any updates to the enrolment's status or list
            commit("setStudent", null);
        },
        async rejectEnrolment({ commit }, enrolmentId) {
            await studentsService.rejectEnrolment(enrolmentId);
            // Similar to approveEnrolment, handle any updates to the enrolment's status or list
            commit("setStudent", null);
        },
    },
    mutations: {
        setStudents(state, students) {
            state.students = students;
        },
        setStudent(state, student) {
            state.student = student;
        },
    },
    getters: {
        getStudentsData(state) {
            return state.students?.data;
        },
        getStudentsMeta(state) {
            return state.students?.meta;
        },
        getStudent(state) {
            return state.student;
        },
    },
};
