/* eslint-disable no-unused-vars */
import usersService from "../services/users.service";

const initialState = { users: null, user: null };

export const users = {
    namespaced: true,
    state: initialState,
    actions: {
        async getUsers({ commit }, options) {
            const users = await usersService.getUsers(options);
            commit("getUsersSuccess", users);
        },

        async addUser({ commit }, newUser) {
            try {
                const user = await usersService.addUser(newUser);
                commit("getUserSuccess", user); // Assumes this mutation updates the users list
            } catch (error) {
                console.error("Failed to add user:", error);
            }
        },

        async deleteUser({ commit }, userId) {
            await usersService.deleteUser(userId);
            commit("deleteUserSuccess", userId); // Assumes deletion of user from the state
        },

        async getUser({ commit }, userId) {
            const user = await usersService.getUser(userId);
            commit("getUserSuccess", user);
        },

        async editUser({ commit }, modifiedUser) {
            const updatedUser = await usersService.editUser(modifiedUser);
            commit("updateUserSuccess", updatedUser); // Update the state with the modified user
        },

        async uploadPic({ commit }, file) {
            const picURL = (
                await usersService.uploadPic(file, this.state.users.user.id)
            ).url;
            commit("successUpload", picURL);
        },
    },
    mutations: {
        getUsersSuccess(state, users) {
            state.users = users;
        },
        successUpload(state, picURL) {
            state.user.profile_image = picURL;
        },
        getUserSuccess(state, user) {
            state.user = user;
        },
        updateUserSuccess(state, updatedUser) {
            // Update the specific user in the state
            // Implementation depends on the structure of your users array
        },
        deleteUserSuccess(state, userId) {
            // Remove the deleted user from the state
            // Implementation depends on the structure of your users array
        },
    },
    getters: {
        getUsersData(state) {
            return state.users?.data;
        },
        getUsersMeta(state) {
            return state.users?.meta;
        },
        getUser(state) {
            return state.user;
        },
        getImage(state) {
            return state.user.profile_image;
        },
    },
};
