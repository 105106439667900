<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 student">
            <div class="col-12">
                <div class="card">
                    <div class="card-header border-bottom">
                        <h5 class="mb-0">Enrolments</h5>
                    </div>
                    <div class="px-0 pb-0 card-body">
                        <div
                            class="px-3 d-flex justify-content-between align-items-center"
                        >
                            <!-- Search Input -->
                            <div class="col-md-4 mb-3">
                                <material-input
                                    id="search"
                                    v-model:value="search"
                                    placeholder="Search..."
                                    class="dataTable-input search-input-table"
                                    variant="static"
                                />
                            </div>
                        </div>

                        <!-- Enrolment Table -->
                        <div
                            class="table-responsive"
                            ref="scrollableTable"
                            @mousedown="startDrag"
                            @mouseup="stopDrag"
                            @mouseleave="stopDrag"
                            @mousemove="onDrag"
                        >
                            <table
                                id="student-list"
                                ref="table"
                                class="table table-flush"
                            >
                                <thead class="thead-light">
                                    <tr>
                                        <th title="FirstName">First Name</th>
                                        <th title="MiddleName">Middle Name</th>
                                        <th title="LastName">Last Name</th>
                                        <th title="DateOfBirth">
                                            Date of Birth
                                        </th>
                                        <th title="Gender">Gender</th>
                                        <th title="Campus">Selected Campus</th>
                                        <th title="YearLevel">Year Level</th>
                                        <th title="MainLanguage">
                                            Main Language
                                        </th>
                                        <th title="PrimaryParentName">
                                            Primary Parent Name
                                        </th>
                                        <th title="PrimaryParentNumber">
                                            Primary Parent Number
                                        </th>
                                        <th title="PrimaryParentEmail">
                                            Primary Parent Email
                                        </th>
                                        <th title="SecondaryParentName">
                                            Secondary Parent Name
                                        </th>
                                        <th title="SecondaryParentNumber">
                                            Secondary Parent Number
                                        </th>
                                        <th title="SecondaryParentEmail">
                                            Secondary Parent Email
                                        </th>
                                        <th title="Address">Address</th>
                                        <th title="Suburb">Suburb</th>
                                        <th title="SchoolName">School</th>
                                        <th title="AustralianCitizen">
                                            Australian Citizen
                                        </th>
                                        <th title="InternationalStudent">
                                            International Student
                                        </th>
                                        <th title="OtherInfo">Other Info</th>
                                        <th title="EnrolledAnotherSchool">
                                            Enrolled Another School
                                        </th>
                                        <th title="AnotherSchool">
                                            Another School
                                        </th>
                                        <th title="PreviouslyEnrolledSchool">
                                            Previously Enrolled School
                                        </th>
                                        <th title="PreviouslySchool">
                                            Previously School
                                        </th>
                                        <th title="ExtraCurricularActivities">
                                            Extra Curricular Activities
                                        </th>
                                        <th title="Disabilities">
                                            Disabilities
                                        </th>
                                        <th title="ImpairmentDetails">
                                            Impairment Details
                                        </th>
                                        <th title="ImageConsent">
                                            Image Consent
                                        </th>
                                        <th title="PrivacyPolicy">
                                            Privacy Policy
                                        </th>
                                        <th title="SubmissionDate">
                                            Submission Date
                                        </th>
                                        <th title="Status">Status</th>
                                        <th
                                            data-sortable="false"
                                            class="text-end"
                                        >
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="text-sm"></tbody>
                            </table>
                        </div>
                    </div>

                    <!-- Pagination -->
                    <div
                        class="d-flex justify-content-between flex-wrap align-items-center"
                    >
                        <!-- Pagination Info -->
                        <div class="ms-3">
                            <p class="text-sm">
                                Showing
                                {{
                                    (metaPage?.CurrentPage - 1) *
                                        metaPage?.PageSize +
                                    1
                                }}
                                to
                                {{
                                    Math.min(
                                        metaPage?.CurrentPage *
                                            metaPage?.PageSize,
                                        metaPage?.TotalCount
                                    )
                                }}
                                of {{ metaPage?.TotalCount }} entries
                            </p>
                        </div>
                        <!-- Pagination Component -->
                        <BasePagination
                            v-model="pagination.currentPage"
                            class="pagination-success pagination-md me-3"
                            :per-page="pagination.perPage"
                            :total="pagination.total"
                            :value="pagination.currentPage"
                            @click="handlePageChange"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import eventTable from "@/mixins/eventTable.js";
import store from "@/store";
import _ from "lodash";

import MaterialInput from "@/components/MaterialInput.vue";
import BasePagination from "@/components/BasePagination.vue";

export default {
    name: "EnrolmentList",
    components: {
        MaterialInput,
        BasePagination,
    },
    data() {
        return {
            search: "",
            dataTable: null,
            pagination: {
                currentPage: 1,
                perPage: 25,
                total: 0,
            },
            pendingFilter: false,
            expandedRows: {},
            isDragging: false,
            startX: 0,
            scrollLeft: 0,
        };
    },
    mixins: [eventTable],
    computed: {
        studentsList() {
            return this.$store.getters["students/getStudentsData"];
        },
        metaPage() {
            return this.$store.getters["students/getStudentsMeta"];
        },
    },
    watch: {
        metaPage: {
            deep: true,
            handler(newVal) {
                this.pagination.total = newVal.TotalCount;
                this.pagination.currentPage = newVal.CurrentPage;
                this.pagination.perPage = newVal.PageSize;
                this.updateDataTable();
            },
        },
        studentsList() {
            this.populateDataTable();
        },
        search: _.debounce(function () {
            this.pagination.currentPage = 1;
            this.fetchEnrolmentsData();
        }, 500),
    },
    async mounted() {
        await this.initializeDataTable();
        await this.fetchEnrolmentsData();

        this.setupEventListeners();
        this.initializeDragScroll();
    },
    methods: {
        startDrag(e) {
            this.isDragging = true;
            this.startX = e.pageX - this.$refs.scrollableTable.offsetLeft;
            this.scrollLeft = this.$refs.scrollableTable.scrollLeft;
        },

        stopDrag() {
            this.isDragging = false;
        },

        onDrag(e) {
            if (!this.isDragging) return;
            e.preventDefault();
            const x = e.pageX - this.$refs.scrollableTable.offsetLeft;
            const walk = x - this.startX;
            this.$refs.scrollableTable.scrollLeft = this.scrollLeft - walk;
        },
        initializeDragScroll() {
            const slider = this.$refs.table; // Adjust this reference to your table container
            let isDown = false;
            let startX;
            let scrollLeft;

            slider.addEventListener("mousedown", (e) => {
                isDown = true;
                slider.classList.add("active");
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });

            slider.addEventListener("mouseleave", () => {
                isDown = false;
                slider.classList.remove("active");
            });

            slider.addEventListener("mouseup", () => {
                isDown = false;
                slider.classList.remove("active");
            });

            slider.addEventListener("mousemove", (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; // The number 3 determines the scroll speed
                slider.scrollLeft = scrollLeft - walk;
            });
        },
        async initializeDataTable() {
            this.dataTable = new DataTable(this.$refs.table, {
                searchable: false,
                fixedHeight: false,
                perPage: this.pagination.perPage,
                perPageSelect: [10, 25, 50, 100, 1000], // Allows changing rows per page
                columns: [
                    // Specify which columns are sortable/searchable
                    // ... Define the properties for each column
                    { select: [18], sortable: false }, // Assuming 18th column is 'Actions'
                ],
            });
            this.dataTable.on("datatable.perpage", (perPage) => {
                this.pagination.perPage = perPage;
                this.fetchEnrolmentsData();
            });
            document.querySelector(".dataTable-bottom").remove();
        },

        async fetchEnrolmentsData() {
            const params = {
                sort: "SubmissionDate",
                page: {
                    number: this.pagination.currentPage,
                    size: this.pagination.perPage,
                },
                filter: {
                    ...(this.search.trim() !== ""
                        ? { search: this.search, pending: "Pending" }
                        : { pending: "Pending" }),
                },
            };
            await store.dispatch("students/getStudents", params);
            this.updateDataTable();
        },

        async fetchFilteredEnrolments() {
            const params = {
                sort: "SubmissionDate",
                page: {
                    number: this.pagination.currentPage,
                    size: this.pagination.perPage,
                },
                filter: this.pending ? { status: "Pending" } : {},
            };

            if (this.search.trim() !== "") {
                params.filter.name = this.search;
            }

            await this.$store.dispatch("students/getStudents", params);
            this.updateDataTable();
        },

        toggleDetails(index) {
            this.$set(this.expandedRows, index, !this.expandedRows[index]);
            this.populateDataTable(); // Refresh the table to show/hide detail rows
        },

        populateDataTable() {
            if (!this.studentsList || this.studentsList.length === 0) {
                this.dataTable.setMessage("No results match your search query");
                return;
            }

            const dataEnrolments = this.studentsList.map((student) => {
                // Primary parent/guardian details
                const primaryParentName = `${student.ParentGuardian.PrimaryFirstName} ${student.ParentGuardian.PrimaryLastName}`;
                const primaryParentNumber =
                    student.ParentGuardian.PrimaryContactNumber;
                const primaryParentEmail = student.ParentGuardian.PrimaryEmail;

                // Secondary parent/guardian details
                const secondaryParentName = `${student.ParentGuardian.SecondaryFirstName} ${student.ParentGuardian.SecondaryLastName}`;
                const secondaryParentNumber =
                    student.ParentGuardian.SecondaryContactNumber;
                const secondaryParentEmail =
                    student.ParentGuardian.SecondaryEmail;
                return [
                    student.FirstName,
                    student.MiddleName,
                    student.LastName,
                    student.DateOfBirth
                        ? new Date(student.DateOfBirth).toLocaleDateString(
                              "en-GB",
                              {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                              }
                          )
                        : "",
                    student.Gender,
                    student.Campus,
                    student.YearLevel,
                    student.MainLanguage,
                    primaryParentName, // Primary parent name
                    primaryParentNumber, // Primary parent number
                    primaryParentEmail, // Primary parent email
                    secondaryParentName, // Secondary parent name
                    secondaryParentNumber, // Secondary parent number
                    secondaryParentEmail, // Secondary parent email
                    student.Address,
                    student.Suburb,
                    student.SchoolName,
                    student.AustralianCitizen ? "Yes" : "No",
                    student.InternationalStudent ? "Yes" : "No",
                    student.OtherInfo,
                    student.EnrolledAnotherSchool ? "Yes" : "No",
                    student.AnotherSchool,
                    student.PreviouslyEnrolledSchool ? "Yes" : "No",
                    student.PreviousSchool,
                    student.ExtraCurricularActivities ? "Yes" : "No",
                    student.Disabilities ? "Yes" : "No",
                    student.ImpairmentDetails,
                    student.ImageConsent ? "Yes" : "No",
                    student.PrivacyPolicy ? "Yes" : "No",
                    student.SubmissionDate
                        ? new Date(student.SubmissionDate).toLocaleDateString(
                              "en-GB",
                              {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                              }
                          )
                        : "",
                    student.Status,
                    // Actions column
                    student.Status === "Pending"
                        ? `<div class="text-end">

                    ${this.actionEditButton(student.StringId, "Edit Enrolment")}
                    ${this.actionApproveButton(student.StringId)}
                    ${this.actionRejectButton(student.StringId)}
                   </div>`
                        : '<div class="text-end">-</div>',
                ];
            });

            this.dataTable.data = [];
            this.dataTable.refresh();
            this.dataTable.insert({ data: dataEnrolments });
            this.eventToCall({
                table: this.dataTable,
                myUserId: null,
                redirectPath: "Edit Enrolment",
                getPath: "students/getStudents",
                textDefaultData: "students",
            });
        },

        actionApproveButton(id) {
            return `<button
                class="btn btn-sm btn-success approve-button"
                data-id="${id}"
                title="Approve Enrolment"
            >
                <i class="fas fa-check"></i>
            </button>`;
        },
        actionRejectButton(id) {
            return `<button
                class="btn btn-sm btn-danger reject-button"
                data-id="${id}"
                title="Reject Enrolment"
            >
                <i class="fas fa-times"></i>
            </button>`;
        },

        setupEventListeners() {
            const dataTableElement = this.$refs.table;
            dataTableElement.addEventListener("click", (event) => {
                const target = event.target;
                if (target.matches(".approve-button")) {
                    const id = target.getAttribute("data-id");
                    this.handleApproveEnrolment(id);
                } else if (target.matches(".reject-button")) {
                    const id = target.getAttribute("data-id");
                    this.handleRejectEnrolment(id);
                }
            });
        },

        async handleApproveEnrolment(id) {
            this.$swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes, approve it!",
                    cancelButtonText: "No, cancel!",
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                })
                .then(async (result) => {
                    if (result.isConfirmed) {
                        try {
                            await this.$store.dispatch(
                                "students/approveEnrolment",
                                id
                            );
                            this.$swal.fire(
                                "Approved!",
                                "Enrolment has been approved.",
                                "success"
                            );
                            this.fetchEnrolmentsData();
                        } catch (error) {
                            this.$swal.fire(
                                "Error!",
                                "Something went wrong.",
                                "error"
                            );
                        }
                    }
                });
        },

        handleRejectEnrolment(id) {
            this.$swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes, reject it!",
                    cancelButtonText: "No, cancel!",
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                })
                .then(async (result) => {
                    if (result.isConfirmed) {
                        try {
                            await this.$store.dispatch(
                                "students/rejectEnrolment",
                                id
                            );
                            this.$swal.fire(
                                "Rejected!",
                                "Enrolment has been rejected.",
                                "success"
                            );
                            this.fetchEnrolmentsData();
                        } catch (error) {
                            this.$swal.fire(
                                "Error!",
                                "Something went wrong.",
                                "error"
                            );
                        }
                    }
                });
        },

        updateDataTable() {
            if (this.dataTable) {
                this.dataTable.options.perPage = this.pagination.perPage;
                this.dataTable.refresh();
            }
        },

        handlePageChange(page) {
            this.pagination.currentPage = page;
            this.fetchEnrolmentsData();
        },
    },
};
</script>

<style>
/* Add this to your CSS file */
.dataTable-table {
    overflow-x: auto;
}

.dataTable-head {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
}

.table-responsive {
    overflow-x: auto;
    max-width: 100%;
    cursor: grab; /* Change cursor to indicate draggability */
}

.table-responsive::-webkit-scrollbar {
    display: none; /* Optional: Hide the scrollbar */
}

.table-responsive:active {
    cursor: grabbing;
}
</style>
