<template>
    <div class="form-group">
        <label :for="id">{{ label }}</label>
        <select
            :id="id"
            class="form-control"
            :name="name"
            v-model="selectedOption"
            @change="onChange($event.target.value)"
        >
            <option disabled value="">{{ placeholder }}</option>
            <option
                v-for="option in options"
                :key="option.value"
                :value="option.value"
            >
                {{ option.text }}
            </option>
        </select>
    </div>
</template>
<script>
export default {
    name: "MaterialDropdown",
    props: {
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: false,
            default: "",
        },
        options: {
            type: Array,
            required: true,
        },
        selected: {
            type: String,
            required: false,
            default: "",
        },
        placeholder: {
            type: String,
            default: "Select an option",
        },
    },
    data() {
        return {
            selectedOption: this.selected,
        };
    },
    methods: {
        onChange(value) {
            this.$emit("update:selected", value);
        },
    },
};
</script>
<style scoped>
/* If you want the styles to apply only within this component, use `scoped` */

.form-control {
    border: 2px solid #ced4da; /* Example border: 2px solid with a light grey color */
    border-radius: 0.25rem; /* Rounded borders */
    padding: 0.375rem 0.75rem; /* Some padding inside the dropdown */
    font-size: 1rem; /* Adjust font size as needed */
}

/* Adjust the focus state for better visibility */
.form-control:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
</style>
