<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Edit Student</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button
                                    class="float-right btn btm-sm"
                                    @click="
                                        //Push to list based on Campus
                                        this.$router.push(routeToStudentsList())
                                    "
                                >
                                    Back to list
                                </material-button>
                            </div>
                        </div>
                    </div>

                    <!--Card body-->
                    <div class="card-body">
                        <form>
                            <div class="row">
                                <!-- Fields for Enrolment -->
                                <div class="col-6">
                                    <material-input
                                        id="firstName"
                                        v-model:value="student.FirstName"
                                        label="First Name"
                                        name="firstName"
                                        variant="static"
                                    ></material-input>
                                </div>
                                <div class="col-6">
                                    <material-input
                                        id="middleName"
                                        v-model:value="student.MiddleName"
                                        label="Middle Name"
                                        name="middleName"
                                        variant="static"
                                    ></material-input>
                                </div>
                                <div class="col-6">
                                    <material-input
                                        id="lastName"
                                        v-model:value="student.LastName"
                                        label="Last Name"
                                        name="lastName"
                                        variant="static"
                                    ></material-input>
                                </div>
                                <div class="col-6">
                                    <material-input
                                        id="dateOfBirth"
                                        v-model:value="student.DateOfBirth"
                                        label="Date of Birth"
                                        name="dateOfBirth"
                                        variant="static"
                                    ></material-input>
                                </div>
                                <div class="col-6">
                                    <material-input
                                        id="YearLevel"
                                        v-model:value="student.YearLevel"
                                        label="Year Level"
                                        name="YearLevel"
                                        variant="static"
                                    ></material-input>
                                </div>
                                <div class="col-6">
                                    <material-input
                                        id="amountOwed"
                                        v-model:value="student.AmountOwed"
                                        label="Amount Owed"
                                        name="amountOwed"
                                        variant="static"
                                    ></material-input>
                                </div>
                                <div class="col-6">
                                    <label for="extraCurricularActivities"
                                        >Quran Memorization</label
                                    >
                                    <material-checkbox
                                        id="extraCurricularActivities"
                                        name="extraCurricularActivities"
                                        :checked="
                                            student.ExtraCurricularActivities
                                        "
                                        @change="
                                            student.ExtraCurricularActivities =
                                                $event.target.checked
                                        "
                                    ></material-checkbox>
                                </div>
                                <div class="col-6">
                                    <label for="imageConsent">
                                        Image Consent
                                    </label>
                                    <material-checkbox
                                        id="imageConsent"
                                        name="imageConsent"
                                        :checked="student.ImageConsent"
                                        @change="
                                            student.ImageConsent =
                                                $event.target.checked
                                        "
                                    ></material-checkbox>
                                </div>
                                <div class="col-6">
                                    <label for="statusSelection"
                                        >Select Status:</label
                                    >
                                    <div id="statusSelection">
                                        <material-radio
                                            v-for="statusOption in statusOptions"
                                            :key="statusOption.id"
                                            :id="statusOption.id"
                                            :name="'status'"
                                            :checked="
                                                student.Status ===
                                                statusOption.id
                                            "
                                            @change="
                                                student.Status = statusOption.id
                                            "
                                        >
                                            {{ statusOption.name }}
                                        </material-radio>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label for="campusSelection"
                                        >Select Campus:</label
                                    >
                                    <div id="campusSelection">
                                        <material-radio
                                            v-for="campus in campuses"
                                            :key="campus.id"
                                            :id="campus.id"
                                            :name="'campus'"
                                            :checked="
                                                student.Campus === campus.id
                                            "
                                            @change="student.Campus = campus.id"
                                        >
                                            {{ campus.name }}
                                        </material-radio>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <material-input
                                        id="medicalConditionsDetails"
                                        v-model:value="
                                            student.MedicalConditionDetails
                                        "
                                        label="Medical Conditions Details"
                                        name="medicalConditionsDetails"
                                        variant="static"
                                    ></material-input>
                                </div>
                                <div class="col-6">
                                    <material-input
                                        id="impairmentDetails"
                                        v-model:value="
                                            student.ImpairmentDetails
                                        "
                                        label="Impairment Details"
                                        name="impairmentDetails"
                                        variant="static"
                                    ></material-input>
                                </div>
                                <!-- Save Button -->
                                <div class="col-12 text-end">
                                    <material-button
                                        class="float-right btn btm-sm"
                                        @click.prevent="handleEdit"
                                        >Save Changes</material-button
                                    >
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialRadio from "@/components/MaterialRadio.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "@/mixins/showSwal";
import _ from "lodash";

export default {
    name: "EditStudent",
    components: {
        MaterialButton,
        MaterialInput,
        MaterialRadio,
        MaterialCheckbox,
    },
    data() {
        return {
            student: {
                Campus: "",
                AmountOwed: "",
                Status: "",
            },
            campuses: [
                { id: "Dallas", name: "Dallas" },
                { id: "Meadow Heights", name: "Meadow Heights" },
            ],
            statusOptions: [
                { id: "Approved", name: "Approved" },
                { id: "Rejected", name: "Rejected" },
                { id: "Pending", name: "Pending" },
                { id: "Expelled", name: "Expelled" },
                { id: "Terminated", name: "Terminated" },
            ],
        };
    },
    async mounted() {
        try {
            await this.$store.dispatch(
                "students/getStudent",
                this.$route.params.id
            );
            this.student = _.omit(
                this.$store.getters["students/getStudent"],
                "links"
            );
        } catch (error) {
            showSwal.methods.showSwal({
                type: "error",
                message: `Oops! Something went wrong!`,
                width: 500,
            });
        }
    },
    mixins: [formMixin],
    methods: {
        async handleEdit() {
            this.resetApiValidation();
            try {
                await this.$store.dispatch(
                    "students/editStudent",
                    _.omit(this.student, "ParentGuardian")
                );
                showSwal.methods.showSwal({
                    type: "success",
                    message: `Student edited!`,
                    width: 500,
                });
                const route = this.routeToStudentsList();

                this.$router.push(route);
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: `Oops! Something went wrong!`,
                    width: 500,
                });
            }
        },

        routeToStudentsList() {
            switch (this.student.Campus) {
                case "Dallas":
                    return { name: "DallasStudentsList" };
                case "Meadow Heights":
                    return { name: "MeadowHeightsStudentsList" };
                default:
                    return { name: "Students List" };
            }
        },
    },
};
</script>
