import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    async getPayments(params) {
        try {
            const response = await axios.get(API_URL + "/payments", {
                headers: authHeader(),
                params: params,
            });

            //if response is 404, return null
            if (response.status === 404) {
                return {
                    data: [],
                    meta: {},
                };
            }
            return {
                data: response.data.Payments, // Adjust to access the Payments array
                meta: response.data.Metadata, // Adjust if necessary
            };
        } catch (error) {
            console.error(error);
            return {
                data: [],
                meta: {},
            };
        }
    },

    async addPayment(payment) {
        try {
            const response = await axios.post(
                API_URL + "/payments/add",
                payment,
                {
                    headers: authHeader(),
                }
            );
            return response.data; // Adjust if necessary
        } catch (error) {
            console.error(error);
            return null;
        }
    },
};
