import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    async getStudents(params) {
        try {
            const response = await axios.get(API_URL + "/students", {
                headers: authHeader(),
                params: params,
            });

            //if response is 404, return null
            if (response.status === 404) {
                return {
                    data: [],
                    meta: {},
                };
            }
            return {
                data: response.data.Students, // Adjust to access the Students array
                meta: response.data.Metadata, // Adjust if necessary
            };
        } catch (error) {
            console.error(error);
            return {
                data: [],
                meta: {},
            };
        }
    },

    async getStudent(studentId) {
        const response = await axios.get(API_URL + "/students/" + studentId, {
            headers: authHeader(),
        });
        return response.data; // Adjust based on the response structure
    },

    async editStudent(student) {
        try {
            const response = await axios.put(
                API_URL + "/students/" + student.Id,
                student,
                {
                    headers: authHeader(),
                }
            );
            return response.data; // Adjust if necessary
        } catch (error) {
            console.error(error);
            return null;
        }
    },

    async approveEnrolment(enrolmentId) {
        const response = await axios.post(
            `${API_URL}/enrolments/${enrolmentId}/approve`,
            {}, // Assuming no payload is needed beyond the enrolment ID
            { headers: authHeader() }
        );
        return response.data;
    },

    async rejectEnrolment(enrolmentId) {
        const response = await axios.post(
            `${API_URL}/enrolments/${enrolmentId}/reject`,
            {}, // Assuming no payload is needed beyond the enrolment ID
            { headers: authHeader() }
        );
        return response.data;
    },
};
