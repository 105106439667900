<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Add Payment</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button
                                    class="float-right btn btm-sm"
                                    @click="
                                        $router.push({
                                            name: 'Payments List',
                                        })
                                    "
                                >
                                    Back to list
                                </material-button>
                            </div>
                        </div>
                    </div>
                    <!--Card body-->
                    <div class="card-body">
                        <form @submit.prevent="handleAdd">
                            <div class="row">
                                <div class="mb-3">
                                    <material-input-search
                                        id="student-search"
                                        label="Student Search"
                                        v-model="searchQuery"
                                        @update:selectedStudent="
                                            handleStudentSelect
                                        "
                                        placeholder="Search by name..."
                                    >
                                        {{
                                            filteredStudents.length > 0
                                                ? ""
                                                : "No students found"
                                        }}
                                    </material-input-search>
                                </div>
                                <div class="mb-3 col-6">
                                    <material-input
                                        id="studentId"
                                        v-model:value="payment.StudentID"
                                        label="Student ID"
                                        name="studentId"
                                        type="text"
                                        variant="static"
                                        required
                                        disabled
                                    ></material-input>
                                    <validation-error
                                        :errors="apiValidationErrors.studentId"
                                    ></validation-error>
                                </div>
                                <div class="mb-3 col-6">
                                    <material-input
                                        id="parentGuardianId"
                                        v-model:value="payment.ParentGuardianID"
                                        label="Parent/Guardian ID"
                                        name="parentGuardianId"
                                        type="text"
                                        variant="static"
                                        required
                                        disabled
                                    ></material-input>
                                </div>
                                <div class="mb-3 col-6">
                                    <material-input
                                        id="studentName"
                                        v-model:value="student.AmountOwed"
                                        label="Amount Owed"
                                        name="amountOwed"
                                        type="number"
                                        variant="static"
                                        required
                                        disabled
                                    ></material-input>
                                </div>
                                <div class="mb-3 col-6">
                                    <material-input
                                        id="amountPaid"
                                        v-model:value="payment.AmountPaid"
                                        label="Amount Paid"
                                        name="amountPaid"
                                        type="number"
                                        variant="static"
                                    ></material-input>
                                    <validation-error
                                        :errors="apiValidationErrors.amountPaid"
                                    ></validation-error>
                                </div>
                                <div class="mb-3 col-6">
                                    <material-input
                                        id="paymentDate"
                                        v-model:value="payment.PaymentDate"
                                        label="Payment Date"
                                        name="paymentDate"
                                        type="date"
                                        variant="static"
                                        required
                                    ></material-input>
                                    <validation-error
                                        :errors="
                                            apiValidationErrors.PaymentDate
                                        "
                                    ></validation-error>
                                </div>
                                <div class="mb-3 col-6">
                                    <material-dropdown
                                        id="paymentMethod"
                                        name="paymentMethod"
                                        label="Payment Method"
                                        v-model="selectedOption"
                                        :options="[
                                            { text: 'Cash', value: 'Cash' },
                                            { text: 'Cheque', value: 'Cheque' },
                                            {
                                                text: 'Bank Transfer',
                                                value: 'Bank Transfer',
                                            },
                                            { text: 'Card', value: 'Card' },
                                            { text: 'Paypal', value: 'Paypal' },
                                            { text: 'Stripe', value: 'Stripe' },
                                            { text: 'Other', value: 'Other' },
                                        ]"
                                        @change="
                                            payment.PaymentMethod =
                                                $event.target.value
                                        "
                                    />
                                    <validation-error
                                        :errors="
                                            apiValidationErrors.paymentMethod
                                        "
                                    ></validation-error>
                                </div>
                                <div class="mb-3 col-6">
                                    <label
                                        for="paymentDetails"
                                        class="form-label"
                                        >Payment Details</label
                                    >
                                    <material-textarea
                                        id="paymentDetails"
                                        v-model:value="payment.PaymentDetails"
                                        label="Payment Details"
                                        name="paymentDetails"
                                        variant="static"
                                    ></material-textarea>
                                </div>
                                <div class="text-end">
                                    <material-button
                                        type="submit"
                                        class="btn btn-primary"
                                        @click.prevent="handleAdd"
                                        >Add Payment</material-button
                                    >
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import MaterialDropdown from "@/components/MaterialDropdown.vue";
import MaterialInputSearch from "@/components/MaterialInputSearch.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "@/mixins/showSwal";

export default {
    name: "AddPayment",
    components: {
        MaterialButton,
        MaterialInput,
        MaterialTextarea,
        MaterialDropdown,
        MaterialInputSearch,
        ValidationError,
    },
    data() {
        return {
            payment: {
                StudentID: "",
                ParentGuardianID: "",
                AmountPaid: "",
                PaymentDate: "",
                PaymentMethod: "",
                PaymentDetails: "Manual Payment Entry",
            },
            apiValidationErrors: {
                studentId: [],
                amountPaid: [],
                PaymentDate: [],
                paymentMethod: [],
                paymentDetails: [],
            },
            student: {},
            selectedOption: "",
            searchQuery: "",
        };
    },
    mixins: [formMixin],
    methods: {
        validateForm() {
            if (!this.payment.StudentID) {
                this.apiValidationErrors.studentId.push(
                    "The student ID field is required."
                );
            }
            if (!this.payment.AmountPaid) {
                this.apiValidationErrors.amountPaid.push(
                    "The amount paid field is required."
                );
            }
            if (this.payment.AmountPaid > this.student.AmountOwed) {
                this.apiValidationErrors.amountPaid.push(
                    "The amount paid cannot be more than the amount owed."
                );
            }
            if (!this.payment.PaymentDate) {
                this.apiValidationErrors.PaymentDate.push(
                    "The payment date field is required."
                );
            }
            if (!this.payment.PaymentMethod) {
                this.apiValidationErrors.paymentMethod.push(
                    "The payment method field is required."
                );
            }
            if (!this.payment.PaymentDetails) {
                this.apiValidationErrors.paymentDetails.push(
                    "The payment details field is required."
                );
            }

            return (
                this.apiValidationErrors.studentId.length === 0 &&
                this.apiValidationErrors.amountPaid.length === 0 &&
                this.apiValidationErrors.PaymentDate.length === 0 &&
                this.apiValidationErrors.paymentMethod.length === 0 &&
                this.apiValidationErrors.paymentDetails.length === 0
            );
        },
        handleStudentSelect(selectedStudent) {
            this.payment.StudentID = selectedStudent.Id;
            this.payment.ParentGuardianID = selectedStudent.ParentGuardianID;
            this.student.AmountOwed = selectedStudent.AmountOwed;
        },
        async handleAdd() {
            //Validate the form
            if (!this.validateForm()) {
                return;
            }

            this.resetApiValidation();
            try {
                await this.$store.dispatch("payments/addPayment", this.payment);

                showSwal.methods.showSwal({
                    type: "success",
                    message: `Payment added!`,
                    width: 500,
                });
                this.$router.push({ name: "Payments List" });
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: `Oops! Something went wrong!`,
                    width: 500,
                });
            }
        },
    },
};
</script>
