<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Edit Enrolment</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button
                                    class="float-right btn btm-sm"
                                    @click="
                                        this.$router.push({
                                            name: 'Enrolments List',
                                        })
                                    "
                                >
                                    Back to list
                                </material-button>
                            </div>
                        </div>
                    </div>
                    <!--Card body-->
                    <div class="card-body">
                        <form>
                            <div class="row">
                                <!-- Fields for Enrolment -->
                                <div class="col-6">
                                    <material-input
                                        id="givenName"
                                        v-model:value="student.FirstName"
                                        label="Given Name"
                                        name="givenName"
                                        variant="static"
                                    ></material-input>
                                </div>
                                <div class="col-6">
                                    <material-input
                                        id="familyName"
                                        v-model:value="student.LastName"
                                        label="Family Name"
                                        name="familyName"
                                        variant="static"
                                    ></material-input>
                                </div>
                                <div class="col-6">
                                    <material-input
                                        id="dateOfBirth"
                                        v-model:value="student.DateOfBirth"
                                        label="Date of Birth"
                                        name="dateOfBirth"
                                        type="date"
                                        variant="static"
                                    ></material-input>
                                </div>
                                <div class="col-6">
                                    <material-input
                                        id="gender"
                                        v-model:value="student.Gender"
                                        label="Gender"
                                        name="gender"
                                        variant="static"
                                    ></material-input>
                                </div>
                                <div class="col-6">
                                    <material-input
                                        id="campusApplyingFor"
                                        v-model:value="student.Campus"
                                        label="Campus Applying For"
                                        name="campusApplyingFor"
                                        variant="static"
                                    ></material-input>
                                </div>
                                <div class="col-6">
                                    <material-input
                                        id="yearLevel"
                                        v-model:value="student.YearLevel"
                                        label="Year Level"
                                        name="yearLevel"
                                        variant="static"
                                    ></material-input>
                                </div>
                                <div class="col-6">
                                    <material-input
                                        id="mainLanguage"
                                        v-model:value="student.MainLanguage"
                                        label="Main Language"
                                        name="mainLanguage"
                                        variant="static"
                                    ></material-input>
                                </div>
                                <div class="col-6">
                                    <material-input
                                        id="address"
                                        v-model:value="student.Address"
                                        label="Address"
                                        name="address"
                                        variant="static"
                                    ></material-input>
                                </div>
                                <div class="col-6">
                                    <material-input
                                        id="suburb"
                                        v-model:value="student.Suburb"
                                        label="Suburb"
                                        name="suburb"
                                        variant="static"
                                    ></material-input>
                                </div>
                                <div class="col-6">
                                    <material-input
                                        id="schoolName"
                                        v-model:value="student.SchoolName"
                                        label="School Name"
                                        name="schoolName"
                                        variant="static"
                                    ></material-input>
                                </div>
                                <div class="col-6">
                                    <label for="australianCitizen">
                                        Australian Citizen
                                    </label>
                                    <material-checkbox
                                        id="australianCitizen"
                                        name="australianCitizen"
                                        :checked="student.AustralianCitizen"
                                        @change="
                                            student.AustralianCitizen =
                                                $event.target.checked
                                        "
                                    >
                                    </material-checkbox>
                                </div>
                                <div class="col-6">
                                    <label for="internationalStudent">
                                        International Student
                                    </label>
                                    <material-checkbox
                                        id="internationalStudent"
                                        name="internationalStudent"
                                        :checked="student.InternationalStudent"
                                        @change="
                                            student.InternationalStudent =
                                                $event.target.checked
                                        "
                                    ></material-checkbox>
                                </div>
                                <div class="col-6">
                                    <material-input
                                        id="otherInfo"
                                        v-model:value="student.OtherInfo"
                                        label="Other Info"
                                        name="otherInfo"
                                        variant="static"
                                    ></material-input>
                                </div>
                                <div class="col-6">
                                    <label for="enrolledAnotherSchool">
                                        Enrolled in Another School
                                    </label>
                                    <material-checkbox
                                        id="enrolledAnotherSchool"
                                        name="enrolledAnotherSchool"
                                        :checked="student.EnrolledAnotherSchool"
                                        @change="
                                            student.EnrolledAnotherSchool =
                                                $event.target.checked
                                        "
                                    ></material-checkbox>
                                </div>
                                <div class="col-6">
                                    <material-input
                                        id="anotherSchool"
                                        v-model:value="student.AnotherSchool"
                                        label="Another School"
                                        name="anotherSchool"
                                        variant="static"
                                    ></material-input>
                                </div>
                                <div class="col-6">
                                    <label for="previouslyEnrolledSchool">
                                        Previously Enrolled School
                                    </label>
                                    <material-checkbox
                                        id="previouslyEnrolledSchool"
                                        name="previouslyEnrolledSchool"
                                        :checked="
                                            student.PreviouslyEnrolledSchool
                                        "
                                        @change="
                                            student.PreviouslyEnrolledSchool =
                                                $event.target.checked
                                        "
                                    ></material-checkbox>
                                </div>
                                <div class="col-6">
                                    <material-input
                                        id="previousSchool"
                                        v-model:value="student.PreviousSchool"
                                        label="Previous School"
                                        name="previousSchool"
                                        variant="static"
                                    ></material-input>
                                </div>
                                <div class="col-6">
                                    <label for="extraCurricularActivities">
                                        Extra Curricular Activities
                                    </label>
                                    <material-checkbox
                                        id="extraCurricularActivities"
                                        name="extraCurricularActivities"
                                        :checked="
                                            student.ExtraCurricularActivities
                                        "
                                        @change="
                                            student.ExtraCurricularActivities =
                                                $event.target.checked
                                        "
                                    ></material-checkbox>
                                </div>
                                <div class="col-6">
                                    <label for="disabilities">
                                        Disabilities
                                    </label>
                                    <material-checkbox
                                        id="disabilities"
                                        name="disabilities"
                                        :checked="student.Disabilities"
                                        @change="
                                            student.Disabilities =
                                                $event.target.checked
                                        "
                                    ></material-checkbox>
                                </div>
                                <div class="col-6">
                                    <material-input
                                        id="impairmentDetails"
                                        v-model:value="
                                            student.ImpairmentDetails
                                        "
                                        label="Impairment Details"
                                        name="impairmentDetails"
                                        variant="static"
                                    ></material-input>
                                </div>
                                <!-- Save Button -->
                                <div class="col-12 text-end">
                                    <material-button
                                        class="float-right btn btm-sm"
                                        @click.prevent="handleEdit"
                                        >Save Changes</material-button
                                    >
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "@/mixins/showSwal";
import _ from "lodash";

export default {
    name: "EditEnrolment",
    components: {
        MaterialButton,
        MaterialInput,
        MaterialCheckbox,
    },
    data() {
        return {
            student: {
                FirstName: "",
                LastName: "",
                DateOfBirth: new Date().toISOString().substr(0, 10),
                Gender: "",
                Campus: "",
                YearLevel: "",
                MainLanguage: "",
                Address: "",
                Suburb: "",
                SchoolName: "",
                AustralianCitizen: false,
                InternationalStudent: false,
                OtherInfo: "",
                EnrolledAnotherSchool: false,
                AnotherSchool: "",
                PreviouslyEnrolledSchool: false,
                PreviousSchool: "",
                ExtraCurricularActivities: false,
                Disabilities: false,
                ImpairmentDetails: "",
                ImageConsent: false,
                PrivacyPolicy: false,
            },
        };
    },
    async mounted() {
        try {
            await this.$store.dispatch(
                "students/getStudent",
                this.$route.params.id
            );

            this.student = _.omit(
                this.$store.getters["students/getStudent"],
                "links"
            );
            this.student.DateOfBirth = this.student.DateOfBirth.split("T")[0];
        } catch (error) {
            showSwal.methods.showSwal({
                type: "error",
                message: `Oops! Something went wrong!`,
                width: 500,
            });
        }
    },
    mixins: [formMixin],
    methods: {
        async handleEdit() {
            this.resetApiValidation();
            try {
                await this.$store.dispatch(
                    "students/editStudent",
                    this.student
                );

                showSwal.methods.showSwal({
                    type: "success",
                    message: `Enrolment edited!`,
                    width: 500,
                });
                this.$router.push({ name: "Enrolments List" });
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: `Oops! Something went wrong!`,
                    width: 500,
                });
            }
        },
    },
};
</script>
