import paymentsService from "../services/payments.service";

const initialState = {
    payments: null,
    payment: null,
};

export const payments = {
    namespaced: true,
    state: initialState,
    actions: {
        async getPayments({ commit }, options) {
            const response = await paymentsService.getPayments(options);
            commit("setPayments", response);
        },
        async addPayment({ commit }, payment) {
            try {
                const response = await paymentsService.addPayment(payment);

                if (response === null) {
                    return null;
                }

                commit("setPayment", response);
            } catch (error) {
                console.error(error);
                commit("setPayment", null);
            }
        },
    },
    mutations: {
        setPayments(state, payments) {
            state.payments = payments;
        },
        setPayment(state, payment) {
            state.payment = payment;
        },
    },
    getters: {
        getPaymentsData(state) {
            return state.payments?.data;
        },
        getPaymentsMeta(state) {
            return state.payments?.meta;
        },
        getPayment(state) {
            return state.payment;
        },
    },
};
