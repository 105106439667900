<template>
    <div class="py-4 container-fluid">
        <!-- User Card -->
        <div class="mt-4 user">
            <div class="col-12">
                <div class="card">
                    <!-- Card Header -->
                    <div class="card-header border-bottom">
                        <div
                            class="d-flex align-items-center justify-content-between"
                        >
                            <h5 class="mb-0">Users</h5>
                            <material-button
                                class="btn btn-sm"
                                @click="$router.push({ name: 'Add User' })"
                            >
                                <i class="fas fa-user-plus me-2"></i> Add User
                            </material-button>
                        </div>
                    </div>

                    <!-- Card Body -->
                    <div class="card-body px-0 pb-0">
                        <!-- Search Input -->
                        <div class="search-block col-2 ms-3">
                            <material-input
                                id="search"
                                v-model:value="search"
                                placeholder="Search..."
                                class="mb-1 dataTable-input search-input-table"
                                variant="static"
                            />
                        </div>

                        <!-- User Table -->
                        <div class="table-responsive">
                            <table
                                id="user-list"
                                ref="table"
                                class="table table-flush"
                            >
                                <thead class="thead-light">
                                    <tr>
                                        <th data-sortable="false">Avatar</th>
                                        <th title="Name">Name</th>
                                        <th title="Email">Email</th>
                                        <th title="Role.Name">Role</th>
                                        <th title="CreatedAt">Created at</th>
                                        <th
                                            data-sortable="false"
                                            class="text-end"
                                        >
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="text-sm"></tbody>
                            </table>
                        </div>
                    </div>

                    <!-- Pagination -->
                    <div
                        class="d-flex justify-content-between flex-wrap align-items-center"
                    >
                        <!-- Pagination Info -->
                        <div class="ms-3">
                            <p class="text-sm">
                                Showing
                                {{
                                    (metaPage?.CurrentPage - 1) *
                                        metaPage?.PageSize +
                                    1
                                }}
                                to
                                {{
                                    Math.min(
                                        metaPage?.CurrentPage *
                                            metaPage?.PageSize,
                                        metaPage?.TotalCount
                                    )
                                }}
                                of {{ metaPage?.TotalCount }} entries
                            </p>
                        </div>
                        <!-- Pagination Component -->
                        <BasePagination
                            v-model="pagination.currentPage"
                            class="pagination-success pagination-md me-3"
                            :per-page="pagination.perPage"
                            :total="pagination.total"
                            :value="pagination.currentPage"
                            @click="handlePageChange"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import eventTable from "@/mixins/eventTable.js";
import store from "@/store";
import _ from "lodash";

import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import BasePagination from "@/components/BasePagination.vue";
export default {
    name: "UsersList",
    components: {
        MaterialButton,
        MaterialInput,
        BasePagination,
    },
    data() {
        return {
            search: "",
            dataTable: null,
            pagination: {
                currentPage: 1,
                perPage: 5,
                total: 0,
            },
        };
    },
    mixins: [eventTable],
    computed: {
        usersList() {
            return this.$store.getters["users/getUsersData"];
        },
        metaPage() {
            return this.$store.getters["users/getUsersMeta"];
        },
    },
    watch: {
        metaPage: {
            deep: true,
            handler(newVal) {
                this.pagination.total = newVal.TotalCount;
                this.pagination.currentPage = newVal.CurrentPage;
                this.pagination.perPage = newVal.PageSize;
                this.updateDataTable();
            },
        },
        usersList() {
            this.populateDataTable();
        },
        search: _.debounce(function () {
            this.pagination.currentPage = 1;
            this.fetchUsersData();
        }, 500),
    },
    async mounted() {
        await this.initializeDataTable();
        await this.fetchUsersData();
    },
    methods: {
        async initializeDataTable() {
            this.dataTable = new DataTable(this.$refs.table, {
                searchable: false,
                fixedHeight: false,
                perPage: this.pagination.perPage,
            });

            // Watch for changes in the DataTable's perPage
            this.dataTable.on("datatable.perpage", (perPage) => {
                this.pagination.perPage = perPage;
                this.fetchUsersData(); // Fetch data with the new perPage value
            });

            document.querySelector(".dataTable-bottom").remove();
        },

        async fetchUsersData() {
            const params = {
                sort: "createdAt",
                page: {
                    number: this.pagination.currentPage,
                    size: this.pagination.perPage,
                },
                filter: this.search.trim() !== "" ? { name: this.search } : {},
            };

            await store.dispatch("users/getUsers", params);
            this.updateDataTable();
        },

        populateDataTable() {
            if (!this.usersList || this.usersList.length === 0) {
                this.dataTable.clear().draw();
                this.dataTable.setMessage("No results match your search query");
                return;
            }

            const dataUsers = this.usersList.map((user) => [
                // Avatar column
                `<div class="avatar-group">
                        <a
                            href="#"
                            class="avatar avatar-md rounded-circle"
                            data-toggle="tooltip"
                            data-placement="top"
                            title=""
                            data-original-title="${user.Name}"
                        >
                            <img
                                alt="Image placeholder"
                                src="${user.ProfileImage}"
                            />
                        </a>
                    </div>`,
                user.Name, // Name
                user.Email, // Email
                user.Role ? user.Role.Name : "No Role", // Role
                user.CreatedAt, // Created At
                // Actions column
                `<div class="text-end">
            ${this.actionEditButton(user.Id, "Edit User")}
            ${this.actionDeleteButton(user.Id, "Delete User")}
        </div>`,
            ]);

            this.dataTable.data = [];
            this.dataTable.refresh();
            this.dataTable.insert({ data: dataUsers });
            this.eventToCall({
                table: this.dataTable,
                myUserId: null,
                redirectPath: "Edit User",
                deletePath: "users/deleteUser",
                getPath: "users/getUsers",
                textDelete: "User deleted successfully.",
                textDefaultData: "users",
            });
        },

        updateDataTable() {
            if (this.dataTable) {
                this.dataTable.options.perPage = this.pagination.perPage;
                this.dataTable.refresh();
            }
        },

        handlePageChange(page) {
            this.pagination.currentPage = page;
            this.fetchUsersData();
        },
    },
};
</script>
