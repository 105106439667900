<template>
    <div class="material-input-search">
        <label :for="id">{{ label }}</label>
        <input
            :id="id"
            v-model="internalQuery"
            @input="debounceInput"
            @focus="showDropdown = true"
            @blur="hideDropdown"
            :placeholder="placeholder"
            class="form-control"
            type="search"
        />
        <div v-if="showDropdown" class="dropdown-menu" style="display: block">
            <a
                v-for="student in filteredStudents"
                :key="student.id"
                class="dropdown-item"
                href="#"
                @click.prevent="selectStudent(student)"
                >{{
                    student.FirstName +
                    " " +
                    student.MiddleName +
                    " " +
                    student.LastName
                }}</a
            >
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import store from "@/store";

export default {
    name: "MaterialInputSearch",
    props: {
        id: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: "Search",
        },
        placeholder: {
            type: String,
            default: "Type to search...",
        },
        debounceTime: {
            type: Number,
            default: 300,
        },
    },
    data() {
        return {
            internalQuery: "",
            showDropdown: false,
            selectedStudent: null,
            students: [],
        };
    },
    computed: {
        studentsList() {
            return this.$store.getters["students/getStudentsData"];
        },
        filteredStudents() {
            if (!this.internalQuery) return this.students;

            const filteredStudents = this.students.filter((student) =>
                student.AmountOwed > 0
                    ? `${student.FirstName} ${student.MiddleName} ${student.LastName}`
                          .toLowerCase()
                          .includes(this.internalQuery.toLowerCase())
                    : false
            );

            return filteredStudents;
        },
    },
    mounted() {
        this.fetchStudents();
    },
    created() {
        this.debounceInput = _.debounce(() => {
            this.emitInput();
        }, this.debounceTime);
    },
    methods: {
        emitInput() {
            this.$emit("input", this.internalQuery);
            this.showDropdown = true;
        },
        selectStudent(student) {
            this.selectedStudent = student;
            this.internalQuery =
                `${student.FirstName} ${student.MiddleName} ${student.LastName}`.trim();
            this.showDropdown = false;
            this.$emit("update:selectedStudent", this.selectedStudent);
        },
        hideDropdown() {
            setTimeout(() => {
                this.showDropdown = false;
            }, 200); // Delay to allow click event to register
        },
        async fetchStudents() {
            const params = {
                page: {
                    number: 1,
                    size: 10000,
                },
                filter: { hasNotPaid: true },
            };
            await store.dispatch("students/getStudents", params);

            // filter students with amount owed
            this.students = this.studentsList.filter(
                (student) => student.AmountOwed > 0
            );

            // sort students by first name
            this.students.sort((a, b) => {
                if (a.FirstName < b.FirstName) {
                    return -1;
                }
                if (a.FirstName > b.FirstName) {
                    return 1;
                }
                return 0;
            });
        },
    },
};
</script>

<style scoped>
.material-input-search {
    position: relative;
    margin-bottom: 1rem;
    width: 100%;
}

.material-input-search .dropdown-menu {
    position: absolute;
    width: 100%;
    z-index: 1000;
}

.material-input-search label {
    display: block;
    margin-bottom: 0.5rem;
}

.material-input-search input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

/* Focus state */
.material-input-search input:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
</style>
