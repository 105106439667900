<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 role">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Roles</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button
                                    class="float-right btn btm-sm"
                                    @click="
                                        this.$router.push({ name: 'Add Role' })
                                    "
                                >
                                    <i class="fas fa-user-plus me-2"></i>
                                    Add Role
                                </material-button>
                            </div>
                        </div>
                    </div>

                    <!-- Card body -->
                    <div class="px-0 pb-0 card-body">
                        <!-- Search -->
                        <div class="dataTable-search search-block col-2 ms-3">
                            <material-input
                                v-model:value="search"
                                placeholder="Search..."
                                class="mb-1 dataTable-input search-input-table"
                                variant="static"
                            />
                        </div>

                        <!-- Table -->
                        <div class="table-responsive">
                            <table
                                id="user-list"
                                ref="table"
                                class="table table-flush"
                            >
                                <thead class="thead-light">
                                    <tr>
                                        <th title="Name" class="w-45">Name</th>
                                        <th title="CreatedAt" class="w-45">
                                            Created at
                                        </th>
                                        <th
                                            data-sortable="false"
                                            class="text-end"
                                        >
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="text-sm"></tbody>
                            </table>
                        </div>
                    </div>

                    <!-- Pagination -->
                    <div
                        class="d-flex justify-content-between flex-wrap align-items-center"
                    >
                        <!-- Pagination Info -->
                        <div class="ms-3">
                            <p class="text-sm">
                                Showing
                                {{
                                    (metaPage?.CurrentPage - 1) *
                                        metaPage?.PageSize +
                                    1
                                }}
                                to
                                {{
                                    Math.min(
                                        metaPage?.CurrentPage *
                                            metaPage?.PageSize,
                                        metaPage?.TotalCount
                                    )
                                }}
                                of {{ metaPage?.TotalCount }} entries
                            </p>
                        </div>
                        <!-- Pagination Component -->
                        <BasePagination
                            v-model="pagination.currentPage"
                            class="pagination-success pagination-md me-3"
                            :per-page="pagination.perPage"
                            :total="pagination.total"
                            :value="pagination.currentPage"
                            @click="handlePageChange"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { DataTable } from "simple-datatables";
import eventTable from "@/mixins/eventTable.js";
import store from "@/store";
import _ from "lodash";

import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import BasePagination from "@/components/BasePagination.vue";

export default {
    name: "RolesList",
    components: {
        MaterialButton,
        MaterialInput,
        BasePagination,
    },
    data() {
        return {
            search: "",
            dataRoles: [],
            pagination: {
                currentPage: 1,
                perPage: 5,
                total: 0,
            },
            dataTable: null,
        };
    },
    mixins: [eventTable],
    computed: {
        rolesList() {
            return this.$store.getters["roles/getRolesData"];
        },
        metaPage() {
            return this.$store.getters["roles/getRolesMeta"];
        },
    },
    watch: {
        metaPage() {
            this.pagination.total = this.metaPage.TotalCount;
            this.pagination.currentPage = this.metaPage.CurrentPage;
            this.pagination.perPage = this.metaPage.PageSize;
        },
        rolesList() {
            this.populateDataTable();
        },
        search: _.debounce(function () {
            this.pagination.currentPage = 1; // Reset to first page on new search
            this.fetchRolesData();
        }, 500),
    },
    async mounted() {
        this.setupDataTable();
        await this.fetchRolesData();
    },
    methods: {
        setupDataTable() {
            this.dataTable = new DataTable(this.$refs.table, {
                searchable: false,
                fixedHeight: false,
                perPage: 5,
            });

            document.querySelector(".dataTable-bottom").remove();
            this.dataTable.label = null;
            this.dataTable.setMessage(
                "<i class='fas fa-circle-notch fa-spin'></i>"
            );
        },

        async fetchRolesData() {
            const params = {
                sort: "CreatedAt",
                page: {
                    number: this.pagination.currentPage,
                    size: this.pagination.perPage,
                },
            };

            // Add filter only if search query is not empty
            if (this.search.trim() !== "") {
                params.filter = { name: this.search };
            }

            await store.dispatch("roles/getRoles", params);
        },

        populateDataTable() {
            if (this.rolesList && this.rolesList.length > 0) {
                this.dataRoles = this.rolesList.map((role) => [
                    role.Name,
                    role.CreatedAt,
                    `<div class="text-end">
                        ${this.actionEditButton(role.Id, "Edit Role")}
                        ${this.actionDeleteButton(role.Id, "Delete Role")}
                     </div>`,
                ]);
                this.dataTable.data = [];
                this.dataTable.refresh();
                this.dataTable.insert({ data: this.dataRoles });
                this.removeEvent();
                this.eventToCall({
                    table: this.dataTable,
                    myUserId: null,
                    redirectPath: "Edit Role",
                    deletePath: "roles/deleteRole",
                    getPath: "roles/getRoles",
                    textDelete: "Role deleted successfully.",
                    textDefaultData: "roles",
                });
            } else {
                this.dataTable.setMessage("No results match your search query");
            }
        },

        handlePageChange(page) {
            this.pagination.currentPage = page;
            this.fetchRolesData();
        },
    },
};
</script>
