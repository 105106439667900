<template>
    <!-- Navbar -->
    <nav
        class="top-0 navbar navbar-expand-lg position-absolute z-index-3"
        :class="
            isBlur ? isBlur : 'shadow-none my-3 navbar-transparent w-100 mt-4'
        "
    >
        <div class="container" :class="isBlur && 'px-1'">
            <router-link
                class="navbar-brand font-weight-bolder ms-lg-0 ms-3"
                :class="darkMode ? 'text-black' : 'text-white'"
                to="/"
                >ICMG Turkish School Dashboard</router-link
            >
        </div>
    </nav>
    <!-- End Navbar -->
</template>

<script>
import downArrWhite from "@/assets/img/down-arrow-white.svg";
import downArrBlack from "@/assets/img/down-arrow-dark.svg";

export default {
    name: "Navbar",
    props: {
        btnBackground: {
            type: String,
            default: "bg-white",
        },
        isBlur: {
            type: String,
            default: "",
        },
        darkMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            downArrWhite,
            downArrBlack,
        };
    },
    computed: {
        darkModes() {
            return {
                "text-dark": this.darkMode,
            };
        },
    },
};
</script>
