<template>
    <nav aria-label="breadcrumb">
        <h6 class="mb-0 font-weight-bolder" :class="color">
            {{ currentPage }}
        </h6>
    </nav>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: "Breadcrumbs",
    props: {
        currentPage: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: "text-dark",
        },
    },
    computed: {
        ...mapState(["isRTL"]),
    },
};
</script>
