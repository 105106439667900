<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 payment">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Payments</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button
                                    class="float-right btn btm-sm"
                                    @click="
                                        this.$router.push({
                                            name: 'Add Payment',
                                        })
                                    "
                                >
                                    <i class="fas fa-coins me-2"></i>
                                    Add Payment
                                </material-button>
                            </div>
                        </div>
                    </div>
                    <div class="px-0 pb-0 card-body">
                        <div
                            class="px-3 d-flex justify-content-between align-items-center"
                        >
                            <!-- Search Input -->
                            <div class="col-md-4 mb-3">
                                <material-input
                                    id="search"
                                    v-model:value="search"
                                    placeholder="Search by Student Name..."
                                    class="dataTable-input search-input-table"
                                    variant="static"
                                />
                            </div>
                        </div>

                        <!-- Add Payment Modal -->
                        <div
                            v-if="showAddPaymentModal"
                            class="modal-dialog"
                            role="document"
                            tabindex="-1"
                        >
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Add Payment</h5>
                                    <button
                                        type="button"
                                        class="close"
                                        aria-label="Close"
                                        @click="showAddPaymentModal = false"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <form @submit.prevent="submitPayment">
                                        <!-- Form fields for payment data -->
                                        <div class="form-group">
                                            <label for="studentId"
                                                >Student ID</label
                                            >
                                            <input
                                                type="number"
                                                v-model="newPayment.StudentID"
                                                class="form-control"
                                                id="studentId"
                                                required
                                            />
                                        </div>
                                        <div class="form-group">
                                            <label for="paymentDate"
                                                >Payment Date</label
                                            >
                                            <input
                                                type="date"
                                                v-model="newPayment.PaymentDate"
                                                class="form-control"
                                                id="paymentDate"
                                                required
                                            />
                                        </div>
                                        <div class="form-group">
                                            <label for="amountPaid"
                                                >Amount Paid</label
                                            >
                                            <input
                                                type="text"
                                                v-model="newPayment.AmountPaid"
                                                class="form-control"
                                                id="amountPaid"
                                                required
                                            />
                                        </div>
                                        <div class="form-group">
                                            <label for="paymentMethod"
                                                >Payment Method</label
                                            >
                                            <input
                                                type="text"
                                                v-model="
                                                    newPayment.PaymentMethod
                                                "
                                                class="form-control"
                                                id="paymentMethod"
                                                required
                                            />
                                        </div>
                                        <div class="form-group">
                                            <label for="paymentDetails"
                                                >Payment Details</label
                                            >
                                            <textarea
                                                v-model="
                                                    newPayment.PaymentDetails
                                                "
                                                class="form-control"
                                                id="paymentDetails"
                                            ></textarea>
                                        </div>
                                        <button
                                            type="submit"
                                            class="btn btn-success"
                                        >
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <!-- Payments Table -->
                        <div class="table-responsive" ref="scrollableTable">
                            <table
                                id="payment-list"
                                ref="table"
                                class="table table-flush"
                            >
                                <thead class="thead-light">
                                    <tr>
                                        <th>First Name</th>
                                        <th>Middle Name</th>
                                        <th>Last Name</th>
                                        <th>Payment Date</th>
                                        <th>Amount Paid</th>
                                        <th>Payment Method</th>
                                        <th>Payment Details</th>
                                    </tr>
                                </thead>
                                <tbody class="text-sm"></tbody>
                            </table>
                        </div>
                    </div>

                    <!-- Pagination -->
                    <div
                        class="d-flex justify-content-between flex-wrap align-items-center"
                    >
                        <!-- Pagination Info -->
                        <div class="ms-3">
                            <p class="text-sm">
                                Showing
                                {{
                                    (metaPage?.CurrentPage - 1) *
                                        metaPage?.PageSize +
                                    1
                                }}
                                to
                                {{
                                    Math.min(
                                        metaPage?.CurrentPage *
                                            metaPage?.PageSize,
                                        metaPage?.TotalCount
                                    )
                                }}
                                of {{ metaPage?.TotalCount }} entries
                            </p>
                        </div>
                        <!-- Pagination Component -->
                        <BasePagination
                            v-model="pagination.currentPage"
                            class="pagination-success pagination-md me-3"
                            :per-page="pagination.perPage"
                            :total="pagination.total"
                            :value="pagination.currentPage"
                            @click="handlePageChange"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DataTable } from "simple-datatables";
import eventTable from "@/mixins/eventTable.js";
import store from "@/store";
import _ from "lodash";

import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import BasePagination from "@/components/BasePagination.vue";

export default {
    name: "PaymentsList",
    components: {
        MaterialButton,
        MaterialInput,
        BasePagination,
    },
    data() {
        return {
            search: "",
            dataTable: null,
            pagination: {
                currentPage: 1,
                perPage: 25,
                total: 0,
            },
            pendingFilter: false,
            expandedRows: {},
            isDragging: false,
            startX: 0,
            scrollLeft: 0,
            showAddPaymentModal: false,
            newPayment: {
                StudentID: null,
                PaymentDate: "",
                AmountPaid: null,
                PaymentMethod: "",
                PaymentDetails: "",
            },
        };
    },
    mixins: [eventTable],
    computed: {
        paymentsList() {
            return this.$store.getters["payments/getPaymentsData"];
        },
        metaPage() {
            return this.$store.getters["payments/getPaymentsMeta"];
        },
    },
    watch: {
        metaPage: {
            deep: true,
            handler(newVal) {
                this.pagination.total = newVal.TotalCount;
                this.pagination.currentPage = newVal.CurrentPage;
                this.pagination.perPage = newVal.PageSize;
                this.updateDataTable();
            },
        },
        paymentsList() {
            this.populateDataTable();
        },
        search: _.debounce(function () {
            this.pagination.currentPage = 1;
            this.fetchPaymentsData();
        }, 500),
    },
    async mounted() {
        await this.initializeDataTable();
        await this.fetchPaymentsData();

        this.initializeDragScroll();
    },
    methods: {
        toggleModal() {
            this.showAddPaymentModal = !this.showAddPaymentModal;
            console.log("Modal state:", this.showAddPaymentModal); // Debugging line
        },
        startDrag(e) {
            this.isDragging = true;
            this.startX = e.pageX - this.$refs.scrollableTable.offsetLeft;
            this.scrollLeft = this.$refs.scrollableTable.scrollLeft;
        },

        stopDrag() {
            this.isDragging = false;
        },

        onDrag(e) {
            if (!this.isDragging) return;
            e.preventDefault();
            const x = e.pageX - this.$refs.scrollableTable.offsetLeft;
            const walk = x - this.startX;
            this.$refs.scrollableTable.scrollLeft = this.scrollLeft - walk;
        },
        initializeDragScroll() {
            const slider = this.$refs.table; // Adjust this reference to your table container
            let isDown = false;
            let startX;
            let scrollLeft;

            slider.addEventListener("mousedown", (e) => {
                isDown = true;
                slider.classList.add("active");
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });

            slider.addEventListener("mouseleave", () => {
                isDown = false;
                slider.classList.remove("active");
            });

            slider.addEventListener("mouseup", () => {
                isDown = false;
                slider.classList.remove("active");
            });

            slider.addEventListener("mousemove", (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; // The number 3 determines the scroll speed
                slider.scrollLeft = scrollLeft - walk;
            });
        },
        async initializeDataTable() {
            this.dataTable = new DataTable(this.$refs.table, {
                searchable: false,
                fixedHeight: false,
                perPage: this.pagination.perPage,
                perPageSelect: [10, 25, 50, 100, 1000], // Allows changing rows per page
                columns: [
                    // Specify which columns are sortable/searchable
                    // ... Define the properties for each column
                    { select: [18], sortable: false }, // Assuming 18th column is 'Actions'
                ],
            });
            this.dataTable.on("datatable.perpage", (perPage) => {
                this.pagination.perPage = perPage;
                this.fetchPaymentsData();
            });
            document.querySelector(".dataTable-bottom").remove();
        },

        async fetchPaymentsData() {
            const params = {
                page: {
                    number: this.pagination.currentPage,
                    size: this.pagination.perPage,
                },
                filter: {
                    search: this.search,
                },
            };
            await store.dispatch("payments/getPayments", params);
            this.updateDataTable();
        },

        toggleDetails(index) {
            this.$set(this.expandedRows, index, !this.expandedRows[index]);
            this.populateDataTable(); // Refresh the table to show/hide detail rows
        },

        populateDataTable() {
            if (!this.paymentsList || this.paymentsList.length === 0) {
                this.dataTable.setMessage("No results match your search query");
                return;
            }

            const dataPayments = this.paymentsList.map((payment) => {
                return [
                    payment.Student.FirstName,
                    payment.Student.MiddleName,
                    payment.Student.LastName,
                    payment.PaymentDate,
                    new Intl.NumberFormat("en-GB", {
                        style: "currency",
                        currency: "AUD",
                    }).format(payment.AmountPaid),
                    payment.PaymentMethod,
                    payment.PaymentDetails,
                ];
            });

            this.dataTable.data = [];
            this.dataTable.refresh();
            this.dataTable.insert({ data: dataPayments });
            this.eventToCall({
                table: this.dataTable,
                myUserId: null,
                redirectPath: "Edit Payments",
                getPath: "payments/getPayments",
                textDefaultData: "payments",
            });
        },

        updateDataTable() {
            if (this.dataTable) {
                this.dataTable.options.perPage = this.pagination.perPage;
                this.dataTable.refresh();
            }
        },

        handlePageChange(page) {
            this.pagination.currentPage = page;
            this.fetchPaymentsData();
        },
    },
};
</script>

<style>
/* Add this to your CSS file */
.dataTable-table {
    overflow-x: auto;
}

.dataTable-head {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
}

.table-responsive {
    overflow-x: auto;
    max-width: 100%;
    cursor: grab; /* Change cursor to indicate draggability */
}

.table-responsive::-webkit-scrollbar {
    display: none; /* Optional: Hide the scrollbar */
}

.table-responsive:active {
    cursor: grabbing;
}

.modal-dialog {
    position: absolute; /* Fixed position to stay in place */
    top: 0; /* Center vertically */
    z-index: 1050; /* Higher than the z-index of other elements */
}
</style>
