import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    async getUsers(params) {
        const response = await axios.get(API_URL + "/users?include=roles", {
            headers: authHeader(),
            params: params,
        });
        return {
            data: response.data.Users, // Access the Users array
            meta: response.data.Metadata, // Access the Metadata object
        };
    },

    async addUser(user) {
        const requestData = {
            Name: user.Name,
            Email: user.Email,
            Role: user.Role[0], // Adjust as per your API requirements
            PlainTextPassword: user.Password,
        };

        const response = await axios.post(API_URL + "/users", requestData, {
            headers: authHeader(),
        });
        return response.data; // Adjust based on how your API responds
    },

    async deleteUser(userId) {
        const response = await axios.delete(API_URL + "/users/" + userId, {
            headers: authHeader(),
        });
        return response.data; // Adjust if needed
    },

    async getUser(userId) {
        const response = await axios.get(
            API_URL + "/users/" + userId + "?include=roles",
            {
                headers: authHeader(),
            }
        );
        return response.data; // Adjust if needed
    },

    async editUser(user) {
        const updatedUserData = {
            Name: user.Name,
            Email: user.Email,
            Role: user.Role[0], // Adjust as per your API requirements
            PlainTextPassword: user.Password,
        };

        const response = await axios.put(
            API_URL + "/users/" + user.Id + "?include=roles",
            updatedUserData,
            {
                headers: authHeader(),
            }
        );
        return response.data; // Adjust if needed
    },

    async uploadPic(pic, userId) {
        const formData = new FormData();
        formData.append("attachment", pic);

        const response = await axios.post(
            API_URL + "/uploads/users/" + userId + "/profile-image",
            formData,
            {
                headers: { "Content-Type": "multipart/form-data" },
            }
        );
        return response.data;
    },
};
