<template>
    <div
        id="sidenav-collapse-main"
        class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100 scrollable-list"
    >
        <ul class="navbar-nav">
            <li
                class="nav-item"
                :class="getRoute() === 'mySchool' ? 'active' : ''"
            >
                <sidenav-collapse
                    collapse-ref="mySchool"
                    :nav-text="getUserName()"
                    :class="getRoute() === 'mySchool' ? 'active' : ''"
                >
                    <template #icon>
                        <img :src="getImage" class="avatar" />
                    </template>
                    <template #list>
                        <ul class="nav pe-0">
                            <!-- nav links -->
                            <sidenav-item
                                :to="{ name: 'User Profile' }"
                                mini-icon="P"
                                text="My Profile"
                            />
                            <sidenav-item
                                :to="{ name: 'Login' }"
                                mini-icon="L"
                                text="Logout"
                                @click="logout"
                            />
                        </ul>
                    </template>
                </sidenav-collapse>
            </li>

            <hr class="horizontal light mt-2 mb-2" />

            <!-- Dashboard -->
            <li
                class="nav-item"
                :class="getRoute() === 'dashboard' ? 'active' : ''"
            >
                <router-link class="nav-link" to="/dashboard">
                    <span class="material-icons-round text-white"
                        >dashboard</span
                    >
                    <span class="sidenav-normal ms-3 text-white"
                        >Dashboard</span
                    >
                </router-link>
            </li>

            <li
                class="nav-item"
                :class="getRoute() === 'enrolments' ? 'active' : ''"
            >
                <!-- Enrolments Section -->
                <sidenav-collapse
                    collapse-ref="enrolments"
                    nav-text="Enrolments"
                    :class="getRoute() === 'enrolments' ? 'active' : ''"
                >
                    <template #icon>
                        <i class="material-icons-round opacity-10">school</i>
                    </template>
                    <template #list>
                        <ul class="nav pe-0">
                            <sidenav-item
                                v-if="
                                    role === 'admin' ||
                                    role === 'DA Admin' ||
                                    role === 'MH Admin'
                                "
                                :to="{ name: 'Enrolments List' }"
                                mini-icon="L"
                                text="Enrolments List"
                            />
                        </ul>
                    </template>
                </sidenav-collapse>
            </li>

            <li
                class="nav-item"
                :class="getRoute() === 'students' ? 'active' : ''"
            >
                <!-- Students Section -->
                <sidenav-collapse
                    collapse-ref="students"
                    nav-text="Students"
                    :class="getRoute() === 'students' ? 'active' : ''"
                >
                    <template #icon>
                        <i class="material-icons-round opacity-10">group</i>
                    </template>
                    <template #list>
                        <ul class="nav pe-0">
                            <sidenav-item
                                v-if="role === 'admin' || role === 'DA Admin'"
                                :to="{ name: 'DallasStudentsList' }"
                                mini-icon="D"
                                text="Dallas"
                            />
                            <sidenav-item
                                v-if="role === 'admin' || role === 'MH Admin'"
                                :to="{ name: 'MeadowHeightsStudentsList' }"
                                mini-icon="MH"
                                text="Meadow Heights"
                            />
                        </ul>
                    </template>
                </sidenav-collapse>
            </li>
            <li
                class="nav-item"
                :class="getRoute() === 'payments' ? 'active' : ''"
            >
                <!-- Payments Section -->
                <sidenav-collapse
                    collapse-ref="payments"
                    nav-text="Payments"
                    :class="getRoute() === 'payments' ? 'active' : ''"
                >
                    <template #icon>
                        <i class="material-icons-round opacity-10">payments</i>
                    </template>
                    <template #list>
                        <ul class="nav pe-0">
                            <sidenav-item
                                v-if="role === 'admin'"
                                :to="{ name: 'Payments List' }"
                                mini-icon="P"
                                text="Payments"
                            />
                        </ul>
                    </template>
                </sidenav-collapse>
            </li>
            <!-- Faculty & Staff Management -->
            <!-- <sidenav-collapse
                collapse-ref="facultyManagement"
                nav-text="Faculty & Staff"
                :class="getRoute() === 'facultyManagement' ? 'active' : ''"
            >
                <template #list>
                    <ul class="nav pe-0">
                        <sidenav-item
                            :to="{ name: 'Staff Profiles' }"
                            mini-icon="P"
                            text="Profiles"
                        />
                        <sidenav-item
                            :to="{ name: 'Staff Schedules' }"
                            mini-icon="S"
                            text="Schedules"
                        />
                        More staff management links 
                    </ul>
                </template>
            </sidenav-collapse> -->

            <!-- Student Records -->
            <!--<sidenav-collapse
                collapse-ref="studentRecords"
                nav-text="Student Records"
                :class="getRoute() === 'studentRecords' ? 'active' : ''"
            >
                <template #list>
                    <ul class="nav pe-0">
                        <sidenav-item
                            :to="{ name: 'Grades' }"
                            mini-icon="G"
                            text="Grades"
                        />
                        <sidenav-item
                            :to="{ name: 'Attendance' }"
                            mini-icon="A"
                            text="Attendance"
                        />
                        More student record links
                    </ul>
                </template>
            </sidenav-collapse> -->

            <!-- Curriculum & Course Management -->
            <!-- <sidenav-collapse
                collapse-ref="curriculumManagement"
                nav-text="Curriculum"
                :class="getRoute() === 'curriculumManagement' ? 'active' : ''"
            >
                <template #list>
                    <ul class="nav pe-0">
                        <sidenav-item
                            :to="{ name: 'Course Outlines' }"
                            mini-icon="C"
                            text="Course Outlines"
                        />
                        <sidenav-item
                            :to="{ name: 'Lesson Plans' }"
                            mini-icon="L"
                            text="Lesson Plans"
                        />
                        More curriculum links 
                    </ul>
                </template>
            </sidenav-collapse> -->

            <!-- Timetable & Scheduling -->
            <!-- <sidenav-collapse
                collapse-ref="timetableScheduling"
                nav-text="Timetable & Scheduling"
                :class="getRoute() === 'timetableScheduling' ? 'active' : ''"
            >
                <template #list>
                    <ul class="nav pe-0">
                        <sidenav-item
                            :to="{ name: 'Teacher Schedules' }"
                            mini-icon="T"
                            text="Teacher Schedules"
                        />
                        <sidenav-item
                            :to="{ name: 'Room Bookings' }"
                            mini-icon="R"
                            text="Room Bookings"
                        />
                    </ul>
                </template>
            </sidenav-collapse> -->

            <!-- School Events & Calendar -->
            <!-- <sidenav-collapse
                collapse-ref="schoolEventsCalendar"
                nav-text="Events & Calendar"
                :class="getRoute() === 'schoolEventsCalendar' ? 'active' : ''"
            >
                <template #list>
                    <ul class="nav pe-0">
                        <sidenav-item
                            :to="{ name: 'School Calendar' }"
                            mini-icon="C"
                            text="School Calendar"
                        />
                        <sidenav-item
                            :to="{ name: 'Event Planning' }"
                            mini-icon="E"
                            text="Event Planning"
                        />
                    </ul>
                </template>
            </sidenav-collapse> -->

            <!-- Communications -->
            <!-- <sidenav-collapse
                collapse-ref="communications"
                nav-text="Communications"
                :class="getRoute() === 'communications' ? 'active' : ''"
            >
                <template #list>
                    <ul class="nav pe-0">
                        <sidenav-item
                            :to="{ name: 'Announcements' }"
                            mini-icon="A"
                            text="Announcements"
                        />
                        <sidenav-item
                            :to="{ name: 'Newsletters' }"
                            mini-icon="N"
                            text="Newsletters"
                        />
                    </ul>
                </template>
            </sidenav-collapse> -->

            <!-- Settings & Configuration -->
            <li class="nav-item">
                <sidenav-collapse
                    v-if="role === 'admin'"
                    collapse-ref="settingsConfiguration"
                    nav-text="Settings & Configuration"
                    :class="
                        getRoute() === 'settingsConfiguration' ? 'active' : ''
                    "
                >
                    <template #icon>
                        <i class="material-icons-round opacity-10">settings</i>
                    </template>
                    <template #list>
                        <ul class="nav pe-0">
                            <sidenav-item
                                v-if="role === 'admin'"
                                :to="{ name: 'Roles List' }"
                                mini-icon="R"
                                text="Roles"
                            />

                            <sidenav-item
                                v-if="role === 'admin'"
                                :to="{ name: 'Users List' }"
                                mini-icon="U"
                                text="Users"
                            />
                        </ul>
                    </template>
                </sidenav-collapse>
            </li>
        </ul>
    </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import { mapState } from "vuex";

export default {
    name: "SidenavList",
    components: {
        SidenavItem,
        SidenavCollapse,
    },
    data() {
        return {
            title: "ICMG Turkish School Dashboard",
            controls: "dashboard",
            isActive: "active",
            profile_image: null,
        };
    },
    computed: {
        ...mapState(["isRTL"]),
        getImage() {
            if (!this.profile_image)
                return require("@/assets/img/placeholder.jpg");
            return this.profile_image;
        },
        role() {
            const user = this.$store.getters["profile/getUserProfile"];
            // Check if user and user.Role are defined to avoid errors
            return user && user.Role && user.Role.Permissions.length > 0
                ? user.Role.Name
                : null;
        },
        userName() {
            const user = this.$store.getters["profile/getUserProfile"];
            return user ? user.Name : "User"; // Replace 'User' with a default name or leave it empty
        },
    },
    async created() {
        try {
            await this.$store.dispatch("profile/getProfile");
            this.profile_image =
                this.$store.getters["profile/getUserProfileImage"];
        } catch (error) {
            // Handle any errors here, such as logging or showing a notification
            console.error("Error fetching profile:", error);
            try {
                await this.$store.dispatch("auth/logout");
            } finally {
                this.$router.push({ name: "Login" });
            }
        }
    },
    methods: {
        getRoute() {
            const routeArr = this.$route.path.split("/");
            return routeArr[1];
        },
        logout() {
            try {
                this.$store.dispatch("auth/logout");
            } finally {
                this.$router.push({ name: "Login" });
            }
        },
        getUserName() {
            const user = this.$store.getters["profile/getUserProfile"];
            return user ? user.Name : "User"; // Replace 'User' with a default name or leave it empty
        },
    },
};
</script>

<style>
.scrollable-list {
    max-height: fit-content;
    overflow-y: auto;
    scrollbar-width: none;
}

.scrollable-list::-webkit-scrollbar {
    width: 0;
}
</style>
